import { useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
// import Logo from '../../assets/logo-text2.png';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { Input, InputGroup } from '../../components/unform/index';
import { Spinner } from '../../components/index';
// import { useAuth } from '../../contexts/auth';
import { useValidator } from '../../hooks/index';
import login from '../../assets/svg/login.svg';
import UsuarioService from 'services__new/UsuarioService';

export default function EsqueciSenha() {
  const history = useHistory();
  const { toast } = useValidator();
  const formRef = useRef();
  const senhaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [emailEnviado, setEmailEnviado] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async (data) => {
    setLoading(true);
    const errors = {};
    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });
    formRef.current.setErrors(errors);
    if (!Object.keys(errors).length) {
      try {
        await UsuarioService.forgotPassword(data);
        setEmail(data?.email);
        setEmailEnviado(true);
        toast('Código enviado para e-mail informado!', { type: 'success' });
      } catch (err) {
        toast(err.mensagem, { type: 'error' });
      }
    }
    setLoading(false);
  };

  const handleSubmitChange = async (data) => {
    setLoading(true);
    const errors = {};
    Object.keys(data).forEach((f) => {
      if (!data[f]) {
        errors[f] = 'Obrigatório';
      }
    });
    senhaRef.current.setErrors(errors);

    if (!Object.keys(errors).length) {
      if (data?.senha === data?.resenha) {
        try {
          const body = {
            email,
            senha: data?.senha,
            codigo: data?.codigo,
          };

          await UsuarioService.createPassword(body);
          toast('Senha alterada com sucesso!', { type: 'success' });
          history.push('/');
        } catch (err) {
          toast(err.mensagem, { type: 'error' });
        }
      } else {
        toast('Senhas não conferem!', { type: 'error' });
      }
    }
    setLoading(false);
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Row className="vh-100 m-0 bg-gray">
      <Col
        className="h-100 d-none d-lg-flex align-items-center p-5"
        lg={8}
        sm={12}
      >
        <div className="h-100 w-100 d-lg-flex align-items-center justify-content-center px-5">
          <img className="img-login" src={login} alt="Login V2" />
        </div>
      </Col>
      <Col
        className="d-flex align-items-center auth-bg px-2 p-lg-5 bg-white-theme"
        lg={4}
        sm={12}
      >
        {!emailEnviado ? (
          <Col className="px-xl-4 mx-auto" sm={8} md={6} lg={12}>
            <p className="mb-4 card-text">
              Digite o e-mail para recuperar a senha
            </p>

            <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
              <Input
                name="email"
                label="Email"
                className="form-control"
                placeholder="Seu email"
              />

              <Button color="primary" className="btn-block" disabled={loading}>
                {loading ? <Spinner /> : 'Enviar código'}
              </Button>
            </Form>
          </Col>
        ) : (
          <Col className="px-xl-4 mx-auto" sm={8} md={6} lg={12}>
            <p className="mb-4 card-text">
              Digite o código enviado por e-mail e a nova senha!
            </p>

            <Form onSubmit={(data) => handleSubmitChange(data)} ref={senhaRef}>
              <Input
                name="codigo"
                label="Código"
                className="form-control"
                type="text"
                placeholder="Código para mudar a senha"
              />
              <InputGroup
                name="senha"
                label="Nova senha"
                type={showPassword ? 'text' : 'password'}
                className="form-control-group"
                placeholder="* * * * *"
                append={
                  <Button size="sm" color="link" onClick={toggleShowPassword}>
                    {showPassword ? (
                      <FiEyeOff size={16} />
                    ) : (
                      <FiEye size={16} />
                    )}
                  </Button>
                }
              />
              <InputGroup
                name="resenha"
                label="Confirme a nova senha"
                type={showPassword ? 'text' : 'password'}
                className="form-control-group"
                placeholder="* * * * *"
                append={
                  <Button size="sm" color="link" onClick={toggleShowPassword}>
                    {showPassword ? (
                      <FiEyeOff size={16} />
                    ) : (
                      <FiEye size={16} />
                    )}
                  </Button>
                }
              />

              <Button color="primary" className="btn-block" disabled={loading}>
                {loading ? <Spinner /> : 'Trocar senha'}
              </Button>
            </Form>
          </Col>
        )}
      </Col>
    </Row>
  );
}
