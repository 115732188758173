import api from 'services/api';

class WebHooksService {
  static getAll(data = {}) {
    try {
      const { page = 1, query = '' } = data;
      return api.get(`/listarwebhooks?page=${page}&${query}`);
    } catch (error) {
      console.error('Erro ao buscar webhooks:', error);
    }
  }
}

export default WebHooksService;
