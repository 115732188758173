import React, { useRef, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import {
  IoDocumentOutline,
  // IoExitOutline,
  IoLogInOutline,
  IoAlarmOutline,
} from 'react-icons/io5';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Form } from '@unform/web';
import Pagination from 'react-paginate';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import AcordoService from 'services__new/AcordoService';
import useValidator from '../../hooks/useValidator';
import { Input, ReactSelectAsync, Select } from '../../components/unform';
// import { maskDocumento } from '../../utils';
import getQuery from '../../utils/getQuery';
import { useAuth } from '../../contexts/auth';
import PessoaService from 'services__new/PessoaService';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function ListarAcordos() {
  const { showLoader, closeLoader, toast, validator } = useValidator();
  const [result, setResult] = useState({
    items: [],
    pages: 0,
    count: 0,
    itemsPorPagina: 0,
  });
  const formRef = useRef();
  const filtrosRef = useRef();
  const debounce = useRef();
  const selectOptions = useRef({});
  const { user } = useAuth();
  const [modal, setModal] = useState(false);
  const [modalHistorico, setModalHistorico] = useState(false);
  const [acordoHistorico, setHistoricoAcordo] = useState([]);

  const toggleModal = () => setModal(!modal);
  const toggleModalHistorico = () => setModalHistorico(!modalHistorico);

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  const getData = async (page = 1) => {
    try {
      showLoader();
      const query = getQuery(filtrosRef.current.getData());
      const payload = {
        page,
        query,
      };
      const { data } = await AcordoService.getAll(payload);
      setResult({
        pages: data.pages,
        count: data.total,
        items: data.acordos,
      });
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  };

  async function handleSubmit(body) {
    const { errors, hasError } = validator(body);
    formRef.current.setErrors(errors);
    if (hasError) {
      return toast();
    }
    body.acordo_id = modal;

    showLoader();
    try {
      const formData = new FormData();
      Object.entries(body).forEach(([key, value]) => {
        if (value) {
          if (Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
          } else {
            formData.append(key, value);
          }
        }
      });

      await AcordoService.create(formData);
      toast('Opt-in realizado com sucesso', { type: 'success' });
      toggleModal();
      getData(1);
    } catch (err) {
      closeLoader();
      toast(err.mensagem);
    }
  }

  const realizarOptOut = async (id) => {
    try {
      showLoader();
      await AcordoService.optOut(id);
      setResult((old) => ({
        ...old,
        items: old.items.map((m) => {
          if (m.id === id) {
            return {
              ...m,
              status: 'OPT-OUT',
            };
          }

          return m;
        }),
      }));
      closeLoader();
      toast('Opt-out realizado com sucesso', { type: 'success' });
    } catch (err) {
      closeLoader();
      toast(err.mensagem);
    }
  };

  const handleOptOut = (id) => {
    swalWithBootstrapButtons
      .fire({
        title: `Contrato ${id}`,
        text: 'Tem certeza que deseja realizar opt-out deste contrato?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
        showLoaderOnConfirm: true,
      })
      .then((res) => {
        if (res.value) {
          realizarOptOut(id);
        }
      });
  };

  async function filtrarConsulta(inputValue, tipo) {
    const query = {
      nome: inputValue,
      tipo,
    };
    const { data } = await PessoaService.get(query);

    return data.map((m) => ({
      documento: m?.Usuario?.documento,
      value: m.id,
      label: m.nome,
    }));
  }

  const optionsConsulta = async (inputValue, tipo) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current[tipo]);

      selectOptions.current[tipo] = setTimeout(() => {
        resolve(filtrarConsulta(inputValue, tipo));
      }, 500);
    });

  async function verDocumento(id, tipo) {
    showLoader();
    try {
      const payload = {
        id,
        tipo,
      };
      const { data } = await AcordoService.getDocumentById(payload);
      const win = window.open(data, '_blank');
      if (win != null) {
        win.focus();
      }
    } catch (err) {
      toast('Erro ao buscar documento!');
    }
    closeLoader();
  }

  async function verHistorico(id) {
    showLoader();
    try {
      const { data } = await AcordoService.getHistoryById(id);
      setHistoricoAcordo(data);
      toggleModalHistorico();
    } catch (err) {
      toast('Erro ao buscar documento!');
    }
    closeLoader();
  }

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3 d-flex justify-content-between">
          <h4 className="color-white mb-0">Acordos</h4>
        </CardHeader>
        <CardBody>
          <Form onSubmit={() => getData()} ref={filtrosRef}>
            <Row>
              <Col>
                <Input
                  className="form-control"
                  placeholder="Número"
                  label="Número"
                  name="id"
                  onChange={filtrarPesquisa}
                />
              </Col>
              {user?.perfil !== 'E' && (
                <Col>
                  <ReactSelectAsync
                    isClearable
                    name="pessoa_id_ec"
                    label="Estabelecimento"
                    placeholder="Selecione"
                    loadOptions={(txt) => optionsConsulta(txt, 'pessoa_id_ec')}
                    onChange={filtrarPesquisa}
                    defaultOptions
                  />
                </Col>
              )}
              {user?.perfil !== 'F' && (
                <Col>
                  <ReactSelectAsync
                    isClearable
                    name="pessoa_id_fomento"
                    label="Financiador"
                    placeholder="Selecione"
                    loadOptions={(txt) =>
                      optionsConsulta(txt, 'pessoa_id_fomento')
                    }
                    onChange={filtrarPesquisa}
                    defaultOptions
                  />
                </Col>
              )}
              <Col>
                <Input
                  className="form-control"
                  placeholder="Protocolo"
                  label="Protocolo"
                  name="protocolo"
                  onChange={filtrarPesquisa}
                />
              </Col>
              <Col>
                <Select
                  name="status"
                  className="form-control"
                  label="Status"
                  onChange={filtrarPesquisa}
                  data={[
                    {
                      value: '',
                      label: 'Status',
                    },
                    {
                      value: 'Ativo',
                      label: 'Ativo',
                    },
                    {
                      value: 'Inativo',
                      label: 'Inativo',
                    },
                    {
                      value: 'OPT-IN',
                      label: 'OPT-IN',
                    },
                    {
                      value: 'OPT-OUT',
                      label: 'OPT-OUT',
                    },
                  ]}
                />
              </Col>
            </Row>
          </Form>
          <Table className="rwd-table min-height" responsive>
            <thead>
              <tr>
                <th>Número</th>
                {user?.perfil !== 'F' && <th>Fom.</th>}
                {user?.perfil !== 'E' && <th>Estab.</th>}
                <th>Data</th>
                <th>Protocolo</th>
                <th>Status</th>
                <th className="text-end">Ações</th>
              </tr>
            </thead>
            <tbody>
              {result.items.map((m) => (
                <tr key={m?.id}>
                  <td data-label="Número">{m?.id}</td>
                  {user?.perfil !== 'E' && (
                    <td data-label="Estab.">
                      {m?.AcordoEc?.Usuario?.documento ? (
                        <>
                          <div>{m?.AcordoEc?.nome}</div>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  )}
                  {user?.perfil !== 'F' && (
                    <td data-label="Finan.">
                      {m?.AcordoFomento?.Usuario?.documento ? (
                        <>
                          <div>{m?.AcordoFomento?.nome}</div>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </td>
                  )}
                  <td data-label="Data">
                    {m?.data_hora_acordo
                      ? new Date(m?.data_hora_acordo).toLocaleDateString(
                          'pt-BR',
                          {
                            month: '2-digit',
                            year: '2-digit',
                            day: '2-digit',
                          }
                        )
                      : 'N/A'}
                  </td>
                  <td data-label="Protocolo">
                    {m?.protocolo ? m?.protocolo : 'N/A'}
                  </td>
                  <td data-label="Status">{m?.status}</td>
                  <td data-label="Ações" className="">
                    <div className="d-flex justify-content-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="light-blue"
                          className="text-decoration-none padding-0-5"
                        >
                          <FiMoreVertical />
                        </DropdownToggle>
                        <DropdownMenu end container="body">
                          {(m?.status === 'Pendente' ||
                            m?.status === 'OPT-OUT') && (
                            <DropdownItem onClick={() => setModal(m?.id)}>
                              <IoLogInOutline className="mr-1" />
                              Opt-in
                            </DropdownItem>
                          )}
                          {m?.status === 'OPT-IN' && user?.perfil !== 'E' && (
                            <DropdownItem
                              onClick={() => {
                                handleOptOut(m.id);
                              }}
                            >
                              <IoLogInOutline className="mr-1" />
                              Opt-out
                            </DropdownItem>
                          )}
                          {m?.status !== 'Pendente' && (
                            <>
                              <DropdownItem
                                onClick={() => {
                                  verHistorico(m.id);
                                }}
                              >
                                <IoAlarmOutline className="mr-1" />
                                Histórico
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  verDocumento(m?.id, 'P');
                                }}
                              >
                                <IoDocumentOutline className="mr-1" />
                                Documento
                              </DropdownItem>
                            </>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
              {result.items?.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <div className="d-flex-wrap justify-content-end">
          <p className="mt-1 pr-2">
            {result.items.length}
            {' de '}
            {result.count}
          </p>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={result.pages}
            onPageChange={({ selected }) => getData(selected + 1)}
            initialPage={0}
            containerClassName="pagination justify-end mr-1"
            activeClassName="active"
          />
        </div>
      </Card>

      {/* <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>OPT-IN acordo n°{modal}</ModalHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col lg={6} sm={12}>
                <Input
                  name="data_assinatura"
                  label="Data assinatura *"
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={6} sm={12}>
                <Select
                  name="tipo_operacao"
                  label="Tipo operação *"
                  className="form-control"
                  data={[
                    {
                      label: 'Selecione',
                      value: '',
                    },
                    {
                      label: 'Criar',
                      value: 'C',
                    },
                    {
                      label: 'Alterar',
                      value: 'A',
                    },
                  ]}
                />
              </Col>
              <Col lg={6} sm={12}>
                <Input
                  name="data_inicio"
                  label="Data início *"
                  type="date"
                  className="form-control"
                />
              </Col>
              <Col lg={6} sm={12}>
                <Input
                  name="data_fim"
                  label="Data fim *"
                  type="date"
                  className="form-control"
                />
              </Col>
              <Col lg={12} sm={12}>
                <Input
                  name="file"
                  label="Contrato Opt-in *"
                  type="file"
                  className="form-control"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Realizar opt-in</Button>
          </ModalFooter>
        </Form>
      </Modal> */}

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>OPT-IN {modal?.nome}</ModalHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col lg={6} sm={12}>
                <Input
                  name="data_assinatura"
                  label="Data assinatura *"
                  className="form-control"
                  type="date"
                />
              </Col>
              <Col lg={6} sm={12}>
                <Select
                  name="tipo_operacao"
                  label="Tipo operação *"
                  className="form-control"
                  data={[
                    {
                      label: 'Selecione',
                      value: '',
                    },
                    {
                      label: 'Criar',
                      value: 'C',
                    },
                    {
                      label: 'Alterar',
                      value: 'A',
                    },
                  ]}
                />
              </Col>
              <Col lg={6} sm={12}>
                <Input
                  name="data_inicio"
                  label="Data início *"
                  type="date"
                  className="form-control"
                />
              </Col>
              <Col lg={6} sm={12}>
                <Input
                  name="data_fim"
                  label="Data fim *"
                  type="date"
                  className="form-control"
                />
              </Col>
              <Col lg={12} sm={12}>
                <Input
                  name="file"
                  label="Contrato Opt-in *"
                  type="file"
                  className="form-control"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Realizar opt-in</Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={modalHistorico} toggle={toggleModalHistorico} size="lg">
        <ModalHeader toggle={toggleModalHistorico}>Histórico</ModalHeader>
        <ModalBody>
          <Table className="rwd-table min-height" responsive>
            <thead>
              <tr>
                <th>Número</th>
                <th>Data</th>
                <th>Protocolo</th>
                <th>Status</th>
                <th className="text-end">Ações</th>
              </tr>
            </thead>
            <tbody>
              {acordoHistorico.map((m) => (
                <tr key={m?.id}>
                  <td data-label="Número">{m?.id}</td>
                  <td data-label="Data">
                    {m?.data_acordo
                      ? new Date(m?.data_acordo).toLocaleDateString('pt-BR', {
                          month: '2-digit',
                          year: '2-digit',
                          day: '2-digit',
                        })
                      : 'N/A'}
                  </td>
                  <td data-label="Protocolo">
                    {m?.protocolo ? m?.protocolo : 'N/A'}
                  </td>
                  <td data-label="Status">{m?.status}</td>
                  <td
                    data-label="Ações"
                    className="d-flex justify-content-lg-end justify-content-sm-between"
                  >
                    {m?.status === 'OPT-OUT' ? (
                      <p>&nbsp;</p>
                    ) : (
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="light-blue"
                          className="text-decoration-none padding-0-5"
                        >
                          <FiMoreVertical />
                        </DropdownToggle>
                        <DropdownMenu end>
                          <DropdownItem
                            onClick={() => {
                              verDocumento(m?.acordo_id, 'H');
                            }}
                          >
                            <IoDocumentOutline className="mr-1" />
                            Documento
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListarAcordos;
