import React, { useRef, useState } from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import Pagination from 'react-paginate';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { Form } from '@unform/web';
import { IoCreateOutline } from 'react-icons/io5';
import useValidator from '../../hooks/useValidator';
import { maskDocumento } from '../../utils';
import getQuery from '../../utils/getQuery';
import { Input, Select } from '../../components/unform';
import { useAuth } from '../../contexts/auth';
import ECService from 'services__new/ECService';

function ListarEstabelecimentoComercial() {
  const { user } = useAuth();
  const filtrosRef = useRef();
  const debounce = useRef();
  const { showLoader, closeLoader, toast } = useValidator();
  const [result, setResult] = useState({
    items: [],
    pages: 0,
    count: 0,
    itemsPorPagina: 0,
  });

  const history = useHistory();

  const handleEdit = (id) => {
    history.push(`/estabelecimentos-comerciais/novo?id=${id}`);
  };

  const getData = async (page = 1) => {
    try {
      showLoader();
      const query = getQuery(filtrosRef.current.getData());
      const payload = {
        page,
        query,
      };
      const { data, headers } = await ECService.getAll(payload);
      const {
        'x-itens-per-page': i,
        'x-total-count': c,
        'x-total-pages': p,
      } = headers;
      setResult({
        pages: Number(p),
        count: Number(c),
        items: data,
        itemsPorPagina: i,
      });
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  };

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3 d-flex justify-content-between">
          <h4 className="color-white mb-0">Estabelecimentos comerciais</h4>
          {user?.perfil !== 'A' && (
            <Link
              className="btn btn-primary"
              to="/estabelecimentos-comerciais/novo"
            >
              Novo
            </Link>
          )}
        </CardHeader>
        <CardBody>
          <Form ref={filtrosRef} onSubmit={() => getData()}>
            <Row>
              <Col>
                <Input
                  className="form-control"
                  placeholder="Nome"
                  label="Nome"
                  name="nome"
                  onChange={filtrarPesquisa}
                />
              </Col>
              <Col>
                <Input
                  className="form-control"
                  placeholder="Documento"
                  label="CPF/CNPJ"
                  name="documento"
                  onChange={filtrarPesquisa}
                />
              </Col>
              <Col>
                <Select
                  name="tipo_pessoa"
                  label="Tipo"
                  onChange={filtrarPesquisa}
                  className="form-control"
                  data={[
                    {
                      value: '',
                      label: 'Tipo',
                    },
                    {
                      value: 'PF',
                      label: 'PF',
                    },
                    {
                      value: 'PJ',
                      label: 'PJ',
                    },
                  ]}
                />
              </Col>
              <Col>
                <Select
                  name="status"
                  className="form-control"
                  label="Status"
                  onChange={filtrarPesquisa}
                  data={[
                    {
                      value: '',
                      label: 'Status',
                    },
                    {
                      value: 'Ativo',
                      label: 'Ativo',
                    },
                    {
                      value: 'Inativo',
                      label: 'Inativo',
                    },
                    {
                      value: 'Pendente',
                      label: 'Pendente',
                    },
                  ]}
                />
              </Col>
            </Row>
          </Form>
          <Table className="rwd-table min-height" responsive>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Tipo</th>
                <th>CPF/CNPJ</th>
                <th>Status</th>
                {user?.perfil !== 'A' && <th className="text-end">Ações</th>}
              </tr>
            </thead>
            <tbody>
              {result.items.map((m) => (
                <tr key={m?.Usuario?.documento}>
                  <td data-label="Nome">{m.nome}</td>
                  <td data-label="Tipo">{m.tipo_pessoa}</td>
                  <td data-label="CPF/CNPJ">
                    {maskDocumento(m?.Usuario?.documento)}
                  </td>
                  <td data-label="Status">{m?.Usuario?.status}</td>
                  {user?.perfil !== 'A' && (
                    <td
                      data-label="Ações"
                      className="d-flex justify-content-lg-end justify-content-sm-between"
                    >
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="light-blue"
                          className="text-decoration-none padding-0-5"
                        >
                          <FiMoreVertical />
                        </DropdownToggle>
                        <DropdownMenu end container="body">
                          <DropdownItem onClick={() => handleEdit(m.id)}>
                            <IoCreateOutline className="mr-1" />
                            Editar
                          </DropdownItem>
                          {/* <DropdownItem>
                          <FiTrash className="mr-1" />
                          Excluir
                        </DropdownItem> */}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  )}
                </tr>
              ))}
              {result.items?.length === 0 && (
                <tr>
                  <td colSpan={5} className="text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <div className="d-flex-wrap justify-content-end">
          <p className="mt-1 pr-2">
            {result.items.length}
            {' de '}
            {result.count}
          </p>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={result.pages}
            onPageChange={({ selected }) => getData(selected + 1)}
            initialPage={0}
            containerClassName="pagination justify-end mr-1"
            activeClassName="active"
          />
        </div>
      </Card>
    </>
  );
}

export default ListarEstabelecimentoComercial;
