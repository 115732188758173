import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import {
  Button,
  Card,
  Col,
  Row,
  CardBody,
  CardHeader,
  CardFooter,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

import { IoAdd, IoTrash } from 'react-icons/io5';
import { Scope } from '@unform/core';
import {
  Input,
  Select,
  Radio,
  MaskInput,
  ReactSelectAsync,
  ReactSelect,
  InputGroup,
  NumberFormat,
} from '../../components/unform';
import useValidator from '../../hooks/useValidator';
import { useAuth } from '../../contexts/auth';
import BrasilService from 'services__new/BrasilService';
import ECService from 'services__new/ECService';
import UsuarioService from 'services__new/UsuarioService';

function Perfil() {
  const { showLoader, closeLoader, toast, validator } = useValidator();
  const [tipoPessoa, setTipoPessoa] = useState('PF');
  const [socios, setSocios] = useState([]);
  const [editData, setEditData] = useState(null);
  const [qualificacoes, setQualificacoes] = useState([]);
  const { user } = useAuth();

  const formRef = useRef();
  const formModalRef = useRef();
  const history = useHistory();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [modal, setModal] = useState(false);

  const selectOptions = useRef();

  const toggleModal = () => setModal(!modal);
  const toggleShowPassword1 = () => setShowPassword1(!showPassword1);
  const toggleShowPassword2 = () => setShowPassword2(!showPassword2);

  const handleSubmit = async (body) => {
    const { errors, hasError } = validator(body, ['complemento']);

    formRef.current.setErrors(errors);

    if (hasError) {
      return toast();
    }
    if (!body.documento) {
      body.documento = tipoPessoa === 'PF' ? body.cpf : body.cnpj;
    }
    body.nome = body.nome || body.nome_fantasia;

    try {
      showLoader();
      body.addSocios = (body?.socios || []).filter((f) => !f.id);
      body.delSocios = editData.socios.filter(
        (f) => !(body?.socios || []).some((s) => s.id === f.id)
      );
      body.socios = (body?.socios || []).filter((f) => f.id);

      await UsuarioService.updateProfile(body);
      toast('Dados alterados com sucesso', { type: 'success' });

      closeLoader();
      history.push('/');
    } catch (error) {
      closeLoader();
      toast(error.mensagem);
    }
  };

  const handleAlterarSenha = async (body) => {
    const { errors, hasError } = validator(body);

    formModalRef.current.setErrors(errors);

    if (hasError) {
      return toast();
    }

    showLoader();
    try {
      await UsuarioService.updatePassword(body);
      toggleModal();
      closeLoader();
      toast('Senha alterada com sucesso', { type: 'success' });
    } catch (err) {
      toast(err.mensagem);
      closeLoader();
    }
  };

  async function buscaCep(cep) {
    if (String(cep || '').length !== 8) {
      return;
    }

    try {
      showLoader();
      const { data } = await BrasilService.getAddressByCep(cep);
      formRef.current.setFieldValue('logradouro', data?.street);
      formRef.current.setFieldValue('estado', data?.state);
      formRef.current.setFieldValue('cidade', data?.city);
      formRef.current.setFieldValue('bairro', data?.neighborhood);
    } catch (error) {
      toast('CEP não encontrado', { type: 'warning' });
    } finally {
      closeLoader();
    }
  }

  async function filtrarConsulta(inputValue) {
    const { data } = await ECService.filterJuristicNature(inputValue);

    return data.map((m) => ({ value: m.id, label: m.descricao }));
  }

  const optionsConsulta = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.current = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  useEffect(() => {
    if (user?.id !== '1') {
      const getQualificacoes = async () => {
        try {
          const { data } = await ECService.getQualifications();
          setQualificacoes(
            data.map((m) => ({
              value: m.id,
              label: m.descricao,
            }))
          );
        } catch (error) {
          toast(error.mensagem);
        }
      };
      getQualificacoes();
    }
  }, []);

  useEffect(() => {
    if (user?.id !== '1') {
      const getEditData = async () => {
        try {
          showLoader();
          const { data } = await UsuarioService.getProfile();
          const tempSocios = (
            data?.PessoaJuridica?.PessoaJuridicaSocios || []
          )?.map((m) => ({
            ...m,
            qualificacao_id: {
              value: m.Qualificacao.id,
              label: m.Qualificacao.descricao,
            },
          }));
          setEditData({
            socios: tempSocios,
          });

          if (tempSocios.length) {
            setSocios(tempSocios);
          }

          setTipoPessoa(data.tipo_pessoa);
          formRef.current.setData({
            ...data,
            ...(data?.Usuario || {}),
            ...(data?.PessoaFisica || {}),
            ...(data?.PessoaJuridica || {}),
            ...(data?.PessoaEndereco || {}),
            natureza_juridica_id: {
              value: data?.PessoaJuridica?.NaturezaJuridica?.id,
              label: data?.PessoaJuridica?.NaturezaJuridica?.descricao,
            },
            socios: tempSocios,
            isPj: data.tipo_pessoa,
          });
          closeLoader();
        } catch (err) {
          toast(err.mensagem);
          closeLoader();
        }
      };

      getEditData();
    }
  }, []);

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3 d-flex justify-content-between">
          <h4 className="color-white mb-0">Perfil</h4>
          <Button color="primary" onClick={toggleModal}>
            Alterar senha
          </Button>
        </CardHeader>
        <Form ref={formRef} onSubmit={handleSubmit} hidden={user?.id === '1'}>
          {user?.perfil === 'A' ? (
            <CardBody>
              <>
                <Row>
                  <Col lg={12}>
                    <span>Dados pessoais</span>
                    <hr />
                  </Col>
                  <Col lg={6} sm={12}>
                    <Input
                      name="nome"
                      label="Nome completo *"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <Input
                      name="email"
                      label="Email *"
                      type="email"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <MaskInput
                      name="documento"
                      label="CPF *"
                      className="form-control"
                      mask={[
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                      ]}
                    />
                  </Col>
                </Row>
              </>
            </CardBody>
          ) : (
            <CardBody>
              <Row>
                <Col lg={12} hidden className="d-none">
                  <Radio
                    name="isPj"
                    label="Tipo estabelecimento"
                    className="radio"
                    defaultChecked="PF"
                    onClick={(e) => {
                      setTipoPessoa(e.target.value);
                    }}
                    disabled
                    hidden
                    data={[
                      { label: 'Pessoa Física', value: 'PF' },
                      { label: 'Pessoa Jurídica', value: 'PJ' },
                    ]}
                  />
                </Col>
              </Row>

              {tipoPessoa === 'PF' ? (
                <>
                  <Row>
                    <Col lg={12}>
                      <span>Dados pessoais</span>
                      <hr />
                    </Col>
                    <Col lg={6} sm={12}>
                      <Input
                        name="nome"
                        label="Nome completo *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        className="form-control"
                        label="Data nascimento *"
                        name="data_nascimento"
                        type="date"
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Select
                        name="sexo"
                        label="Sexo *"
                        className="form-control"
                        data={[
                          { label: 'Selecione', value: '' },
                          { label: 'Masculino', value: 'M' },
                          { label: 'Feminino', value: 'F' },
                        ]}
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <MaskInput
                        name="cpf"
                        label="CPF *"
                        className="form-control"
                        mask={[
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <MaskInput
                        name="rg"
                        label="RG *"
                        className="form-control"
                        mask={[
                          /[0-9]/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                        ]}
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <MaskInput
                        name="telefone"
                        label="Telefone *"
                        className="form-control"
                        placeholder="(00) 90000-0000"
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="email"
                        label="Email *"
                        type="email"
                        className="form-control"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} className="mt-3">
                      <span>Endereço</span>
                      <hr />
                    </Col>
                    <Col lg={2} md={6} sm={12}>
                      <MaskInput
                        name="cep"
                        label="CEP *"
                        className="form-control"
                        onChange={(e) =>
                          buscaCep(e.target.value.replace(/[^0-9]/g, ''))
                        }
                        mask={[
                          /[0-9]/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <Input
                        name="logradouro"
                        label="Logradouro *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="numero"
                        label="Número *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="complemento"
                        label="Complemento"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Input
                        name="bairro"
                        label="Bairro *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Input
                        name="cidade"
                        label="Cidade *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Input
                        name="estado"
                        label="Estado *"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col lg={12}>
                      <span>Dados da empresa</span>
                      <hr />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Input
                        name="razao_social"
                        label="Razão social *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Input
                        name="nome_fantasia"
                        label="Nome fantasia *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <ReactSelectAsync
                        isClearable
                        name="natureza_juridica_id"
                        label="Natureza jurídica *"
                        placeholder="Selecione"
                        loadOptions={optionsConsulta}
                        defaultOptions
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        className="form-control"
                        label="Data abertura *"
                        name="data_abertura"
                        type="date"
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <MaskInput
                        name="cnpj"
                        label="CNPJ *"
                        className="form-control"
                        mask={[
                          /[0-9]/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '/',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <MaskInput
                        name="telefone"
                        label="Telefone *"
                        className="form-control"
                        placeholder="(00) 90000-0000"
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="email"
                        label="Email *"
                        type="email"
                        className="form-control"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} className="mt-3">
                      <span>Dados representante legal</span>
                      <hr />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="representante_legal_nome"
                        label="Nome completo *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <MaskInput
                        name="representante_legal_cpf"
                        label="CPF *"
                        className="form-control"
                        mask={[
                          /[0-9]/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <MaskInput
                        name="representante_legal_telefone"
                        label="Telefone *"
                        className="form-control"
                        placeholder="(00) 90000-0000"
                        mask={[
                          '(',
                          /[0-9]/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="representante_legal_email"
                        label="Email *"
                        type="email"
                        className="form-control"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12} className="mt-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <span>Sócios</span>
                        <Button
                          type="button"
                          size="sm"
                          className="weight-600"
                          onClick={() =>
                            setSocios((old) =>
                              old.concat({
                                timestamps: Date.now(),
                              })
                            )
                          }
                        >
                          <IoAdd color="#fff" size={16} className="mr-2" />
                          Adicionar sócio
                        </Button>
                      </div>
                      <hr />
                    </Col>
                  </Row>
                  {socios.map((m, i) => (
                    <Row key={m.id || m.timestamps}>
                      <Col lg={12} className="d-flex align-items-center mb-1">
                        <h4 className="mr-3 mb-0">Sócio {i + 1}</h4>

                        <Button
                          type="button"
                          color="danger"
                          className="weight-600"
                          size="sm"
                          onClick={() =>
                            setSocios((old) =>
                              old.filter((f) =>
                                m.id
                                  ? m.id !== f.id
                                  : f.timestamps !== m.timestamps
                              )
                            )
                          }
                        >
                          <IoTrash color="#fff" size={16} className="mr-2" />
                          Remover sócio
                        </Button>
                      </Col>
                      <Scope path={`socios[${i}]`}>
                        <Col lg={3} md={6} sm={12}>
                          <Input
                            name="nome"
                            label="Nome completo *"
                            className="form-control"
                          />
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <MaskInput
                            name="cpf"
                            label="CPF *"
                            className="form-control"
                            mask={[
                              /[0-9]/,
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '.',
                              /\d/,
                              /\d/,
                              /\d/,
                              '-',
                              /\d/,
                              /\d/,
                            ]}
                          />
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <Input
                            name="email"
                            label="Email *"
                            type="email"
                            className="form-control"
                          />
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <Input
                            className="form-control"
                            label="Data nascimento *"
                            name="data_nascimento"
                            type="date"
                          />
                        </Col>
                        <Col lg={3} md={6} sm={12}>
                          <ReactSelect
                            name="qualificacao_id"
                            label="Qualificação"
                            placeholder="Selecione"
                            options={qualificacoes}
                          />
                        </Col>
                        <Col lg={6} md={6} sm={12}>
                          <Radio
                            name="assina_conjunto"
                            label="Assina em conjunto ?"
                            keyName={i}
                            className="radio"
                            data={[
                              { label: 'Sim', value: true },
                              { label: 'Não', value: false },
                            ]}
                          />
                        </Col>
                        <Input
                          name="id"
                          disabled
                          readOnly
                          hidden
                          className="d-none"
                          value={m?.id || ''}
                        />
                      </Scope>
                    </Row>
                  ))}

                  <Row>
                    <Col lg={12} className="mt-3">
                      <span>Endereço empresa</span>
                      <hr />
                    </Col>
                    <Col lg={2} md={6} sm={12}>
                      <MaskInput
                        name="cep"
                        label="CEP *"
                        className="form-control"
                        onChange={(e) =>
                          buscaCep(e.target.value.replace(/[^0-9]/g, ''))
                        }
                        mask={[
                          /[0-9]/,
                          /\d/,
                          '.',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                      />
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                      <Input
                        name="logradouro"
                        label="Logradouro *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="numero"
                        label="Número *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="complemento"
                        label="Complemento"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Input
                        name="bairro"
                        label="Bairro *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Input
                        name="cidade"
                        label="Cidade *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <Input
                        name="estado"
                        label="Estado *"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </>
              )}
              {user?.perfil === 'F' && (
                <>
                  <Row>
                    <Col lg={12} className="mt-3">
                      <span>Dados Bancários</span>
                      <hr />
                    </Col>
                    <Col lg={2} md={6} sm={12}>
                      <Select
                        name="banco_tipo_conta"
                        label="Tipo Conta *"
                        className="form-control"
                        data={[
                          { label: 'Conta Corrente', value: 'CC' },
                          { label: 'Conta de Depósito', value: 'CD' },
                          { label: 'Conta Garantia', value: 'CG' },
                          { label: 'Conta Investimento', value: 'CI' },
                          { label: 'Conta de Pagamento', value: 'PG' },
                          { label: 'Conta Poupança', value: 'PP' },
                        ]}
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="banco_ispb"
                        label="ISPB *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={2} md={6} sm={12}>
                      <Input
                        name="banco_agencia"
                        label="Agência *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={3} md={6} sm={12}>
                      <Input
                        name="banco_numero_conta"
                        label="Número conta *"
                        className="form-control"
                      />
                    </Col>
                    <Col lg={2} md={6} sm={12}>
                      <Input
                        name="banco_compe"
                        label="Compe *"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className="mt-3">
                      <span>Informações adicionais</span>
                      <hr />
                    </Col>
                    <Col lg={2} sm={12}>
                      <Input
                        name="fator_compra"
                        label="Fator compra *"
                        className="form-control"
                        type="number"
                        step=".01"
                      />
                    </Col>
                    <Col lg={2} sm={12}>
                      <NumberFormat
                        name="outras_despesas"
                        label="Valor despesa *"
                        className="form-control"
                        prefix="R$"
                      />
                    </Col>
                    <Col lg={4} sm={12}>
                      <div className="justify-between">
                        <Input
                          name="dias_liquidacao_agenda"
                          label="Ver agenda com liquidação mínima de *"
                          className="form-control"
                          type="number"
                          min="2"
                        />
                        <p className="mt-5 mr-10">dias da data atual</p>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          )}
          <CardFooter className="justify-content-between d-flex">
            <p className="mb-0 text-muted"> * (Obrigatório)</p>
            <Button type="submit" color="primary">
              Salvar
            </Button>
          </CardFooter>
        </Form>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Alterar senha</ModalHeader>
        <Form ref={formModalRef} onSubmit={handleAlterarSenha}>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <InputGroup
                  name="senha"
                  label="Senha atual"
                  type={showPassword1 ? 'text' : 'password'}
                  className="form-control-group"
                  placeholder="* * * * *"
                  append={
                    <Button
                      size="sm"
                      color="link"
                      onClick={toggleShowPassword1}
                    >
                      {showPassword1 ? (
                        <FiEyeOff size={16} />
                      ) : (
                        <FiEye size={16} />
                      )}
                    </Button>
                  }
                />
              </Col>
              <Col lg={12}>
                <InputGroup
                  name="novaSenha"
                  label="Nova senha"
                  type={showPassword2 ? 'text' : 'password'}
                  className="form-control-group"
                  placeholder="* * * * *"
                  append={
                    <Button
                      size="sm"
                      color="link"
                      onClick={toggleShowPassword2}
                    >
                      {showPassword2 ? (
                        <FiEyeOff size={16} />
                      ) : (
                        <FiEye size={16} />
                      )}
                    </Button>
                  }
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Alterar senha</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Perfil;
