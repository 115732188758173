import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

import AuthRoute from './authRoute';

import Login from '../pages/Login/index';
import EsqueciSenha from '../pages/Login/esqueciSenha';

import Dashboard from '../pages/Dashboard/index';
import Layout from '../layouts';
// import PreCadastro from '../pages/PreCadastro';
import CriarEstabelecimentoComercial from '../pages/EstabelecimentoComercial/criar';
import ListarEstabelecimentoComercial from '../pages/EstabelecimentoComercial/listar';
import ListarFomento from '../pages/Fomento/listar';
import CriarFomento from '../pages/Fomento/criar';
import ListarAcordos from '../pages/Acordos/listar';
import CriarAcordo from '../pages/Acordos/criar';
import Agenda from '../pages/Agenda';
import ListarUsuariosInternos from '../pages/UsuariosInternos/listar';
import CriarUsuariosInternos from '../pages/UsuariosInternos/criar';
import Perfil from '../pages/Perfil/criar';
import ListarOperacoes from '../pages/Operacoes/listar';
import ListarLogs from '../pages/Logs/listar';
import ListarWebhooks from '../pages/Webhooks/listar';
import ListarConciliação from '../pages/Conciliacao/listar';
import ListarIntegracoes from '../pages/Integrador/listar';

const Routes = () => {
  const { loading, signed, user } = useAuth();
  return (
    <BrowserRouter>
      <Switch>
        {loading || signed ? (
          <Layout>
            <Switch>
              <AuthRoute path="/" exact component={Dashboard} />
              <AuthRoute path="/acordos" exact component={ListarAcordos} />
              <AuthRoute path="/acordos/novo" exact component={CriarAcordo} />
              <AuthRoute
                path="/usuarios"
                exact
                component={ListarUsuariosInternos}
              />
              <AuthRoute
                path="/usuarios/novo"
                exact
                component={CriarUsuariosInternos}
              />
              <AuthRoute path="/perfil" exact component={Perfil} />
              <AuthRoute path="/operacoes" exact component={ListarOperacoes} />

              {user?.perfil === 'F' || user?.perfil === 'A' ? (
                <>
                  <AuthRoute
                    path="/estabelecimentos-comerciais"
                    exact
                    component={ListarEstabelecimentoComercial}
                  />
                  {user?.perfil === 'F' && (
                    <AuthRoute
                      path="/estabelecimentos-comerciais/novo"
                      exact
                      component={CriarEstabelecimentoComercial}
                    />
                  )}
                  <AuthRoute path="/acordos" exact component={ListarAcordos} />
                  <AuthRoute
                    path="/acordos/novo"
                    exact
                    component={CriarAcordo}
                  />
                  {user?.perfil === 'F' && (
                    <AuthRoute path="/agenda" exact component={Agenda} />
                  )}
                  {user?.perfil === 'A' && (
                    <>
                      <AuthRoute
                        path="/financiadores"
                        exact
                        component={ListarFomento}
                      />
                      <AuthRoute
                        path="/financiadores/novo"
                        exact
                        component={CriarFomento}
                      />
                      <AuthRoute path="/logs" exact component={ListarLogs} />
                      <AuthRoute
                        path="/webhooks"
                        exact
                        component={ListarWebhooks}
                      />
                      <AuthRoute
                        path="/conciliacao"
                        exact
                        component={ListarConciliação}
                      />
                      <AuthRoute
                        path="/integrador"
                        exact
                        component={ListarIntegracoes}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <AuthRoute path="/agenda" exact component={Agenda} />
                  <AuthRoute
                    path="/financiadores"
                    exact
                    component={ListarFomento}
                  />
                  <AuthRoute
                    path="/financiadores/novo"
                    exact
                    component={CriarFomento}
                  />
                </>
              )}

              <Redirect to="/" />
            </Switch>
          </Layout>
        ) : (
          <>
            <Route path="/" exact component={Login} />
            {/* <Route path="/pre-cadastro" exact component={PreCadastro} /> */}
            <Route path="/esqueci-minha-senha" exact component={EsqueciSenha} />
            <Redirect to="/" />
          </>
        )}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
