import api from 'services/api';

class DashboardService {
  static getSent(startDate = '', endDate = '') {
    try {
      return api.get(
        `/dashboard/fomeccad?dataInicio=${startDate}&dataFim=${endDate}`
      );
    } catch (error) {
      console.error('Erro ao buscar dashboard de enviados:', error);
    }
  }

  static getError(startDate = '', endDate = '') {
    try {
      return api.get(
        `/dashboard/evofomec?dataInicio=${startDate}&dataFim=${endDate}`
      );
    } catch (error) {
      console.error('Erro ao buscar dashboard de erros:', error);
    }
  }

  static getOutdated(startDate = '', endDate = '') {
    try {
      return api.get(
        `/dashboard/somaoper?dataInicio=${startDate}&dataFim=${endDate}`
      );
    } catch (error) {
      console.error('Erro ao buscar dashboard de vencidos:', error);
    }
  }

  static getDelivered(startDate = '', endDate = '') {
    try {
      return api.get(
        `/dashboard/qtdeoper?dataInicio=${startDate}&dataFim=${endDate}`
      );
    } catch (error) {
      console.error('Erro ao buscar dashboard de entregues:', error);
    }
  }

  static getPending(startDate = '', endDate = '') {
    try {
      return api.get(
        `/dashboard/qtdeoperfomen?dataInicio=${startDate}&dataFim=${endDate}`
      );
    } catch (error) {
      console.error('Erro ao buscar dashboard de pendentes:', error);
    }
  }

  static getFinished(startDate = '', endDate = '') {
    try {
      return api.get(
        `/dashboard/qtdeoperec?dataInicio=${startDate}&dataFim=${endDate}`
      );
    } catch (error) {
      console.error('Erro ao buscar dashboard de finalizados:', error);
    }
  }
}

export default DashboardService;
