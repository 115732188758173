export function adicionarDias(dias = 0, anos = 0) {
  // Converte a data base para um objeto Date
  const data = new Date();

  // Adiciona os dias à data
  data.setDate(data.getDate() + dias + 1);

  // Obtém o ano, mês e dia da data resultante
  const ano = data.getFullYear() + anos;
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
  const dia = String(data.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário

  // Retorna a data no formato "yyyy-mm-dd"
  return `${ano}-${mes}-${dia}`;
}

export function formatarData(dataString) {
  if (!dataString) {
    return 'N/A';
  }

  const splitString = dataString.split('-');

  return `${splitString[2]}/${splitString[1]}/${splitString[0]}`;
}

export function calcularDiasRestantes(dataAlvo) {
  const dataAlvoObj = new Date(dataAlvo);
  const dataAtual = new Date();
  const diferencaEmMilissegundos = dataAlvoObj - dataAtual;
  const diasRestantes = Math.ceil(
    diferencaEmMilissegundos / (1000 * 60 * 60 * 24)
  );

  return diasRestantes;
}
