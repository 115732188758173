import api from 'services/api';

class PessoaService {
  static get(query = {}) {
    try {
      return api.get('/pessoas', { params: query });
    } catch (error) {
      console.error('Erro ao buscar pessoas:', error);
    }
  }
}

export default PessoaService;
