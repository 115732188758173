import api from 'services/api';

class ECService {
  static getQualifications() {
    try {
      return api.get('/qualificacao');
    } catch (error) {
      console.error('Erro ao buscar qualificações:', error);
    }
  }

  static filterJuristicNature(searchText = '') {
    try {
      return api.get(`/natureza?descricao=${searchText}`);
    } catch (error) {
      console.error('Erro ao filtrar naturezas jurídicas:', error);
    }
  }

  static getDetails(id = '') {
    try {
      return api.get(`/listardetalheecfo?id=${id}`);
    } catch (error) {
      console.error('Erro ao buscar detalhes do EC:', error);
    }
  }

  static getAll(data = {}) {
    try {
      const { page = 1, query = '' } = data;
      return api.get(`/listarecfo?perfil=E&page=${page}&${query}`);
    } catch (e) {
      console.error('Erro ao buscar ECs:', e);
    }
  }

  static update(data = {}) {
    try {
      const { id = '', body = {} } = data;
      return api.put(`/editarec/${id}`, body);
    } catch (e) {
      console.error('Erro ao editar EC:', e);
    }
  }

  static create(data = {}) {
    try {
      const { body = {} } = data;
      return api.post('/cadastraec', body);
    } catch (e) {
      console.error('Erro ao criar EC:', e);
    }
  }

  static createFomento(data = {}) {
    try {
      const { body = {} } = data;
      return api.post('/cadastrafom', body);
    } catch (e) {
      console.error('Erro ao criar fomento:', e);
    }
  }
}

export default ECService;
