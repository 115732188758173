import { useRef, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
// import Logo from '../../assets/logo-text2.png';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { Input, InputGroup } from '../../components/unform/index';
import { Spinner } from '../../components/index';
import { useAuth } from '../../contexts/auth';
import login from '../../assets/svg/login.svg';
import logo from '../../assets/svg/logo.svg';
import pinPad from '../../assets/images/pin_pad.png';
import useValidator from '../../hooks/useValidator';
import { templateName } from '../../layouts';
import UsuarioService from 'services__new/UsuarioService';

export default function Login() {
  const { signIn } = useAuth();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { toast, validator } = useValidator();

  const handleSubmit = async (dados) => {
    const { errors, hasError } = validator(dados);
    formRef.current.setErrors(errors);
    if (hasError) {
      toast();
      return;
    }
    setLoading(true);
    try {
      const { data } = await UsuarioService.login(dados);
      setLoading(false);
      signIn(data);
    } catch (err) {
      toast(err.mensagem);
      setLoading(false);
    }
    setLoading(false);
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Row className="vh-100 m-0 bg-gray">
      <Col
        className="h-100 d-none d-lg-flex align-items-center p-5"
        lg={8}
        sm={12}
      >
        <div className="h-100 w-100 d-lg-flex align-items-center justify-content-center px-5">
          <img className="img-login" src={login} alt="Login V2" />
        </div>
      </Col>
      <Col
        className="d-flex align-items-center auth-bg px-2 p-lg-5 bg-white-theme"
        lg={4}
        sm={12}
      >
        <Col className="px-xl-4 mx-auto col-12" sm={8} md={6} lg={12}>
          <div className="d-flex">
            <div style={{ width: 'calc(100% - 35px)' }}>
              <h4
                className="font-weight-bold mb-1 card-title d-flex align-items-center"
                style={{
                  color: '#002b49',
                }}
              >
                <img
                  src={logo}
                  alt="finanblue"
                  style={{
                    width: 'calc(90% - 120px)',
                  }}
                />
                &nbsp; | {templateName}
              </h4>
              <h4
                className="font-weight-bold"
                style={{
                  color: '#262626',
                }}
              >
                Arranjo de pagamentos
              </h4>
            </div>
            <span>
              <img
                src={pinPad}
                alt="pinPad"
                style={{
                  objectFit: 'contain',
                  width: '40px',
                  height: '70px',
                }}
              />
            </span>
          </div>
          <p className="mb-4 card-text">
            Por favor faça o login para prosseguir
          </p>

          <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
            <Input
              name="email_documento"
              label="CNPJ ou e-mail participante"
              className="form-control"
              placeholder="Seu email ou documento"
              defaultValue={process.env.REACT_APP_EMAIL}
            />
            <InputGroup
              name="senha"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              className="form-control-group"
              placeholder="* * * * *"
              defaultValue={process.env.REACT_APP_SENHA}
              append={
                <Button size="sm" color="link" onClick={toggleShowPassword}>
                  {showPassword ? <FiEyeOff size={16} /> : <FiEye size={16} />}
                </Button>
              }
            />

            <Button
              color="blue"
              className="btn-block mt-2 mb-2"
              disabled={loading}
            >
              {loading ? <Spinner /> : 'Login'}
            </Button>
            <hr />
            <div className="d-flex justify-content-center">
              <Link to="/esqueci-minha-senha" className="pt-1">
                Esqueceu a senha ?
              </Link>
            </div>

            {/* <p className="text-center mt-4">
              <span className="mr-25">Novo na plataforma ? </span>
              <Link to="/pre-cadastro">
                <span>Pré-Cadastro</span>
              </Link>
            </p> */}
          </Form>
        </Col>
      </Col>
    </Row>
  );
}
