import api from 'services/api';

class LogService {
  static getAll(data = {}) {
    try {
      const { page = 1, query = '' } = data;
      return api.get(`/listarlog?page=${page}&${query}`);
    } catch (e) {
      console.error('Erro ao buscar logs:', e);
    }
  }
}

export default LogService;
