import React from 'react';

// import { Container } from './styles';

function CheckBox({ id, ...rest }) {
  return (
    <span className="ml-2">
      <input className="inp-cbx" type="checkbox" id={id} {...rest} />
      <label
        className="cbx"
        htmlFor={id}
        style={{ padding: 1, marginBottom: 0 }}
      >
        <div className="d-flex">
          <span className="align-items-center d-flex">
            <svg width="12px" height="10px">
              <svg id="check" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1" />
              </svg>
            </svg>
          </span>
        </div>
      </label>
    </span>
  );
}

export default CheckBox;
