/* eslint-disable no-nested-ternary */
const customStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    borderRadius: 'var(--border-radius)',
    boxShadow: isFocused ? '0 3px 10px 0 rgba(34, 41, 47, 0.1)' : null,
    borderColor: isFocused
      ? 'var(--dark-blue) !important'
      : 'rgba(var(--theme-color-rgb), 0.15)',
    backgroundColor: 'transparent',
  }),
  option: (styles, { isDisabled, isSelected }) => ({
    ...styles,
    cursor: isDisabled ? 'not-allowed' : 'default',
    color: isDisabled
      ? 'rgba(var(--theme-color-rgb), 0.5)'
      : isSelected
      ? '#fff'
      : 'var(--body-color)',
  }),
};

export const theme = (val) => ({
  ...val,
  colors: {
    ...val.colors,
    neutral0: 'var(--body-bg)',
    neutral30: 'rgba(var(--theme-color-rgb), 0.2)',
    neutral80: 'var(--body-color)',
    primary25: 'rgba(var(--dark-blue-rgb), 0.2)',
    primary50: 'rgba(var(--dark-blue-rgb), 0.4)',
    primary: 'var(--dark-blue)',
  },
});

export default customStyles;
