/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useRef, useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Form } from '@unform/web';
import Pagination from 'react-paginate';
import { FiMoreVertical } from 'react-icons/fi';
import {
  Card,
  CardBody,
  CardHeader,
  Table,
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  ModalBody,
  ModalHeader,
  Modal,
} from 'reactstrap';
import useValidator from '../../hooks/useValidator';
import { Input, Select } from '../../components/unform';
import getQuery from '../../utils/getQuery';
// import { useAuth } from '../../contexts/auth';
import 'react-json-view-lite/dist/index.css';
import LogService from 'services__new/LogService';

function formatarData(dataString) {
  if (dataString) {
    const data = new Date(dataString);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const hora = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');

    return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
  }
}

function ListaLogs() {
  const { showLoader, closeLoader, toast } = useValidator();
  const [result, setResult] = useState({
    items: [],
    pages: 0,
    count: 0,
    itemsPorPagina: 0,
  });
  const filtrosRef = useRef();
  const debounce = useRef();
  const [modal, setModal] = useState(false);

  const [reqResData, setReqResData] = useState(null);

  const toggleModal = (e) => {
    setModal(!modal);
    setReqResData(e);
  };

  const getData = async (page = 1) => {
    try {
      showLoader();
      const query = getQuery(filtrosRef.current.getData());
      const payload = {
        page,
        query,
      };
      const { data } = await LogService.getAll(payload);
      setResult({
        pages: Number(data.pages),
        count: Number(data.total),
        items: data.logs,
      });
    } catch (err) {
      toast(err.mensagem);
    } finally {
      closeLoader();
    }
  };

  function filtrarPesquisa() {
    clearTimeout(debounce.current);
    debounce.current = setTimeout(() => {
      filtrosRef.current.submitForm();
    }, 500);
  }

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3 d-flex justify-content-between">
          <h4 className="color-white mb-0">Logs</h4>
        </CardHeader>
        <CardBody>
          <Form onSubmit={() => getData()} ref={filtrosRef}>
            <Row>
              <Col>
                <Input
                  className="form-control"
                  name="url"
                  label="URL"
                  onChange={filtrarPesquisa}
                  isClearable
                />
              </Col>
              <Col>
                <Select
                  name="method"
                  className="form-control"
                  label="Método HTTP"
                  onChange={filtrarPesquisa}
                  data={[
                    {
                      value: '',
                      label: 'Selecione',
                    },
                    {
                      value: 'post',
                      label: 'POST',
                    },
                    {
                      value: 'put',
                      label: 'PUT',
                    },
                    {
                      value: 'get',
                      label: 'GET',
                    },
                    {
                      value: 'delete',
                      label: 'DELETE',
                    },
                  ]}
                />
              </Col>
              <Col>
                <Select
                  name="tipo"
                  className="form-control"
                  label="Status"
                  onChange={filtrarPesquisa}
                  data={[
                    {
                      value: '',
                      label: 'Selecione',
                    },
                    {
                      value: 'sucesso',
                      label: 'Sucesso',
                    },
                    {
                      value: 'erro',
                      label: 'Erro',
                    },
                  ]}
                />
              </Col>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="data_inicio"
                  label="Data início"
                  onChange={filtrarPesquisa}
                  type="date"
                  isClearable
                />
              </Col>
              <Col lg={2}>
                <Input
                  className="form-control"
                  name="data_fim"
                  label="Data fim"
                  onChange={filtrarPesquisa}
                  type="date"
                />
              </Col>
            </Row>
          </Form>
          <Table className="rwd-table min-height" responsive>
            <thead>
              <tr>
                <th>Data</th>
                <th>URL</th>
                <th>Método</th>
                <th>Status code</th>
                <th>Status</th>
                <th className="text-end">Ações</th>
              </tr>
            </thead>
            <tbody>
              {result?.items?.map((m) => (
                <tr key={m?.id}>
                  <td data-label="Data">{formatarData(m?.createdAt)}</td>
                  <td data-label="URL">{m?.url}</td>
                  <td data-label="Método">{m?.method}</td>
                  <td data-label="Status code">{m?.status_code}</td>
                  <td data-label="Status">{m?.tipo}</td>
                  <td data-label="Ações">
                    <div className="d-flex justify-content-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="light-blue"
                          className="text-decoration-none padding-0-5"
                        >
                          <FiMoreVertical />
                        </DropdownToggle>
                        <DropdownMenu end container="body">
                          {m?.body || m?.response ? (
                            <DropdownItem
                              onClick={() =>
                                toggleModal({
                                  request: m?.body,
                                  response: m?.response,
                                })
                              }
                            >
                              Ver Request / Response
                            </DropdownItem>
                          ) : (
                            <></>
                          )}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </td>
                </tr>
              ))}
              {result.items?.length === 0 && (
                <tr>
                  <td colSpan={8} className="text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
        <div className="d-flex-wrap justify-content-end">
          <p className="mt-1 pr-2">
            {result.items.length}
            {' de '}
            {result.count}
          </p>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={result.pages}
            onPageChange={({ selected }) => getData(selected + 1)}
            initialPage={0}
            containerClassName="pagination justify-end mr-1"
            activeClassName="active"
          />
        </div>
      </Card>

      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Dados</ModalHeader>

        <ModalBody>
          <Row>
            <Col>
              <h5>Request</h5>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <textarea disabled style={{ width: '100%', height: '300px' }}>
                {reqResData?.request}
              </textarea>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5>Response</h5>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <textarea disabled style={{ width: '100%', height: '300px' }}>
                {reqResData?.response}
              </textarea>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ListaLogs;
