import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Button,
  Card,
  Col,
  Row,
  CardBody,
  CardHeader,
  CardFooter,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { IoAdd, IoTrash } from 'react-icons/io5';
import { Scope } from '@unform/core';
import {
  Input,
  Radio,
  MaskInput,
  ReactSelect,
  ReactSelectAsync,
  Select,
  NumberFormat,
} from '../../components/unform';
import useValidator from '../../hooks/useValidator';
import BrasilService from 'services__new/BrasilService';
import ECService from 'services__new/ECService';

function CriarFomento() {
  const formRef = useRef();
  const { showLoader, closeLoader, toast, validator } = useValidator();
  const history = useHistory();
  const [socios, setSocios] = useState([]);
  const [editData, setEditData] = useState(null);
  const [precadastro, setPrecadastro] = useState(false);
  const [qualificacoes, setQualificacoes] = useState([]);

  const [tipoErp, setTipoErp] = useState('');

  const query = useMemo(
    () =>
      String(history?.location?.search || '')
        .replace('?', '')
        .split('&')
        .reduce((a, b) => {
          const [key, value] = b.split('=');
          a[key] = value;
          return a;
        }, {}),
    [history?.location?.search]
  );

  const selectOptions = useRef();

  const handleSubmit = async (body) => {
    const { errors, hasError } = validator(body, ['complemento']);
    formRef.current.setErrors(errors);

    if (hasError) {
      return toast();
    }

    body.documento = body.cnpj;
    body.nome = body.nome_fantasia;

    try {
      showLoader();
      if (query?.id && precadastro === false) {
        body.addSocios = (body?.socios || []).filter((f) => !f.id);
        body.delSocios = editData?.socios?.filter(
          (f) => !(body?.socios || []).some((s) => s.id === f.id)
        );
        body.socios = (body?.socios || []).filter((f) => f.id);

        const payload = {
          id: query?.id,
          body,
        };
        await ECService.update(payload);
        toast('Financiador alterado com sucesso', { type: 'success' });
      } else {
        const formData = new FormData();
        Object.entries(body).forEach(([key, value]) => {
          if (value) {
            if (Array.isArray(value)) {
              formData.append(key, JSON.stringify(value));
            } else {
              formData.append(key, value);
            }
          }
        });
        const payload = {
          body: formData,
        };
        await ECService.createFomento(payload);
        toast('Financiador criado com sucesso', { type: 'success' });
      }

      closeLoader();
      history.push('/financiadores');
    } catch (error) {
      closeLoader();
      toast(error.mensagem);
    }
  };

  async function buscaCep(cep) {
    if (String(cep || '').length !== 8) {
      return;
    }

    try {
      showLoader();
      const { data } = await BrasilService.getAddressByCep(cep);
      formRef.current.setFieldValue('logradouro', data?.street);
      formRef.current.setFieldValue('estado', data?.state);
      formRef.current.setFieldValue('cidade', data?.city);
      formRef.current.setFieldValue('bairro', data?.neighborhood);
    } catch (error) {
      toast('CEP não encontrado', { type: 'warning' });
    } finally {
      closeLoader();
    }
  }

  async function filtrarConsulta(inputValue) {
    const { data } = await ECService.filterJuristicNature(inputValue);

    return data.map((m) => ({ value: m.id, label: m.descricao }));
  }

  const optionsConsulta = async (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(selectOptions.current);

      selectOptions.current = setTimeout(() => {
        resolve(filtrarConsulta(inputValue));
      }, 500);
    });

  useEffect(() => {
    const getQualificacoes = async () => {
      try {
        const { data } = await ECService.getQualifications();
        setQualificacoes(data);
      } catch (error) {
        toast(error.mensagem);
      }
    };
    getQualificacoes();
  }, []);

  useEffect(() => {
    const getEditData = async (id) => {
      try {
        showLoader();
        const { data } = await ECService.getDetails(id);
        if (data?.Usuario?.status !== 'Pendente') {
          const tempSocios = (
            data?.PessoaJuridica?.PessoaJuridicaSocios || []
          )?.map((m) => ({
            ...m,
            qualificacao_id: {
              value: m.Qualificacao.id,
              label: m.Qualificacao.descricao,
            },
          }));
          setEditData({
            socios: tempSocios,
          });

          if (tempSocios.length) {
            setSocios(tempSocios);
          }

          formRef.current.setData({
            ...data,
            ...(data?.Usuario || {}),
            ...(data?.PessoaFisica || {}),
            ...(data?.PessoaJuridica || {}),
            ...(data?.PessoaEndereco || {}),
            natureza_juridica_id: {
              value: data?.PessoaJuridica?.NaturezaJuridica?.id,
              label: data?.PessoaJuridica?.NaturezaJuridica?.descricao,
            },
            socios: tempSocios,
            isPj: data.tipo_pessoa,
          });
        } else {
          setPrecadastro(true);
          formRef.current.setFieldValue('nome_fantasia', data?.nome);
          formRef.current.setFieldValue('cnpj', data?.Usuario?.documento);
          formRef.current.setFieldValue('email', data?.Usuario?.email);
        }
        closeLoader();
      } catch (err) {
        toast(err.mensagem);
        closeLoader();
      }
    };

    if (query.id) {
      getEditData(query.id);
    }
  }, [query]);

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3">
          <h4 className="color-white mb-0">
            {query?.id ? 'Alterar financiador' : 'Novo financiador'}
          </h4>
        </CardHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <CardBody>
            <Row>
              <Col lg={12}>
                <span>Dados da empresa</span>
                <hr />
              </Col>
              <Col lg={4} sm={12}>
                <Input
                  name="razao_social"
                  label="Razão social *"
                  className="form-control"
                />
              </Col>
              <Col lg={4} sm={12}>
                <Input
                  name="nome_fantasia"
                  label="Nome fantasia *"
                  className="form-control"
                />
              </Col>
              <Col lg={4} sm={12}>
                <ReactSelectAsync
                  isClearable
                  name="natureza_juridica_id"
                  label="Natureza jurídica *"
                  placeholder="Selecione"
                  loadOptions={optionsConsulta}
                  defaultOptions
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Input
                  className="form-control"
                  label="Data abertura *"
                  name="data_abertura"
                  type="date"
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <MaskInput
                  name="cnpj"
                  label="CNPJ *"
                  className="form-control"
                  mask={[
                    /[0-9]/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <MaskInput
                  name="telefone"
                  label="Telefone *"
                  className="form-control"
                  placeholder="(00) 90000-0000"
                  mask={[
                    '(',
                    /[0-9]/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Input
                  name="email"
                  label="Email *"
                  type="email"
                  className="form-control"
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                {query?.id && precadastro === false ? (
                  <Select
                    name="status"
                    label="Status"
                    className="form-control"
                    data={[
                      { label: 'Ativo', value: 'Ativo' },
                      { label: 'Inativo', value: 'Inativo' },
                    ]}
                  />
                ) : (
                  <Input type="file" name="file" label="Contrato social *" />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mt-3">
                <span>Dados representante legal</span>
                <hr />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Input
                  name="representante_legal_nome"
                  label="Nome completo *"
                  className="form-control"
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <MaskInput
                  name="representante_legal_cpf"
                  label="CPF *"
                  className="form-control"
                  mask={[
                    /[0-9]/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                  ]}
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <MaskInput
                  name="representante_legal_telefone"
                  label="Telefone *"
                  className="form-control"
                  placeholder="(00) 90000-0000"
                  mask={[
                    '(',
                    /[0-9]/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Input
                  name="representante_legal_email"
                  label="Email *"
                  type="email"
                  className="form-control"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mt-3">
                <div className="d-flex justify-content-between align-items-center">
                  <span>Sócios</span>
                  <Button
                    type="button"
                    size="sm"
                    className="weight-600"
                    onClick={() =>
                      setSocios((old) =>
                        old.concat({
                          timestamps: Date.now(),
                        })
                      )
                    }
                  >
                    <IoAdd color="#fff" size={16} className="mr-2" />
                    Adicionar sócio
                  </Button>
                </div>
                <hr />
              </Col>
            </Row>
            {socios.map((m, i) => (
              <Row key={m.id || m.timestamps}>
                <Col lg={12} className="d-flex align-items-center mb-1">
                  <h4 className="mr-3 mb-0">Sócio {i + 1}</h4>

                  <Button
                    type="button"
                    color="danger"
                    className="weight-600"
                    size="sm"
                    onClick={() =>
                      setSocios((old) =>
                        old.filter((f) =>
                          m.id ? m.id !== f.id : f.timestamps !== m.timestamps
                        )
                      )
                    }
                  >
                    <IoTrash color="#fff" size={16} className="mr-2" />
                    Remover sócio
                  </Button>
                </Col>
                <Scope path={`socios[${i}]`}>
                  <Col lg={3} md={6} sm={12}>
                    <Input
                      name="nome"
                      label="Nome completo *"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <MaskInput
                      name="cpf"
                      label="CPF *"
                      className="form-control"
                      mask={[
                        /[0-9]/,
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        '.',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                      ]}
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <Input
                      name="email"
                      label="Email *"
                      type="email"
                      className="form-control"
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <Input
                      className="form-control"
                      label="Data nascimento *"
                      name="data_nascimento"
                      type="date"
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <ReactSelect
                      name="qualificacao_id"
                      label="Qualificação"
                      placeholder="Selecione"
                      options={qualificacoes}
                    />
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <Radio
                      name="assina_conjunto"
                      label="Assina em conjunto ?"
                      keyName={i}
                      className="radio"
                      data={[
                        { label: 'Sim', value: true },
                        { label: 'Não', value: false },
                      ]}
                    />
                  </Col>
                  <Input
                    name="id"
                    disabled
                    readOnly
                    hidden
                    className="d-none"
                    value={m?.id || ''}
                  />
                </Scope>
              </Row>
            ))}
            <Row>
              <Col lg={12} className="mt-3">
                <span>Endereço empresa</span>
                <hr />
              </Col>
              <Col lg={2} md={6} sm={12}>
                <MaskInput
                  name="cep"
                  label="CEP *"
                  className="form-control"
                  onChange={(e) =>
                    buscaCep(e.target.value.replace(/[^0-9]/g, ''))
                  }
                  mask={[
                    /[0-9]/,
                    /\d/,
                    '.',
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <Input
                  name="logradouro"
                  label="Logradouro *"
                  className="form-control"
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Input
                  name="numero"
                  label="Número *"
                  className="form-control"
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Input
                  name="complemento"
                  label="Complemento"
                  className="form-control"
                />
              </Col>
              <Col lg={4} sm={12}>
                <Input
                  name="bairro"
                  label="Bairro *"
                  className="form-control"
                />
              </Col>
              <Col lg={4} sm={12}>
                <Input
                  name="cidade"
                  label="Cidade *"
                  className="form-control"
                />
              </Col>
              <Col lg={4} sm={12}>
                <Input
                  name="estado"
                  label="Estado *"
                  className="form-control"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} className="mt-3">
                <span>Dados Bancários</span>
                <hr />
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Select
                  name="banco_tipo_conta"
                  label="Tipo Conta *"
                  className="form-control"
                  data={[
                    { label: 'Conta Corrente', value: 'CC' },
                    { label: 'Conta de Depósito', value: 'CD' },
                    { label: 'Conta Garantia', value: 'CG' },
                    { label: 'Conta Investimento', value: 'CI' },
                    { label: 'Conta de Pagamento', value: 'PG' },
                    { label: 'Conta Poupança', value: 'PP' },
                  ]}
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Input
                  name="banco_ispb"
                  label="ISPB *"
                  className="form-control"
                />
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Input
                  name="banco_agencia"
                  label="Agência (sem dígito verificador)* "
                  className="form-control"
                />
              </Col>
              <Col lg={3} md={6} sm={12}>
                <Input
                  name="banco_numero_conta"
                  label="Número conta (Com dígito verificador separado por traço) *"
                  className="form-control"
                />
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Input
                  name="banco_compe"
                  label="Código do banco (Compe) *"
                  className="form-control"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={12} className="mt-3">
                <span>Integração ERP</span>
                <hr />
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Select
                  name="erp_finanblue"
                  label="ERP Finanblue *"
                  className="form-control"
                  data={[
                    { label: 'Livework', value: 'Livework' },
                    { label: 'Gestor', value: 'Gestor' },
                    { label: 'Outros', value: 'Outros' },
                  ]}
                  onChange={(e) => setTipoErp(e?.target?.value)}
                />
              </Col>
              <Col lg={2} md={6} sm={12}>
                <Input
                  name="codigo_erp"
                  label="Código ERP *"
                  className="form-control"
                />
              </Col>
              {tipoErp === 'Outros' && (
                <Col>
                  <Input name="url" label="URL *" className="form-control" />
                </Col>
              )}
            </Row>

            <Row>
              <Col lg={12} className="mt-3">
                <span>Informações adicionais</span>
                <hr />
              </Col>
              <Col lg={2} sm={12}>
                <Input
                  name="fator_compra"
                  label="Fator compra *"
                  className="form-control"
                  type="number"
                  step=".01"
                />
              </Col>
              <Col lg={2} sm={12}>
                <NumberFormat
                  name="outras_despesas"
                  label="Valor despesa *"
                  className="form-control"
                  prefix="R$"
                />
              </Col>
              <Col lg={4} sm={12}>
                <div className="justify-between">
                  <Input
                    name="dias_liquidacao_agenda"
                    label="Ver agenda com liquidação mínima de *"
                    className="form-control"
                    type="number"
                    min="2"
                  />
                  <p className="mt-5 mr-10">dias da data atual</p>
                </div>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="justify-content-between d-flex">
            <p className="mb-0 text-muted"> * (Obrigatório)</p>
            <Button type="submit" color="primary">
              {query?.id ? 'Alterar' : 'Cadastrar'}
            </Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
}

export default CriarFomento;
