import React, {
  useEffect,
  //  useRef,
  useState,
} from 'react';
import { FiMoreVertical } from 'react-icons/fi';
import {
  // IoDocumentOutline,
  // IoExitOutline,
  // IoLogInOutline,
  // IoAlarmOutline,
  IoCreateOutline,
  IoTrashBinOutline,
} from 'react-icons/io5';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';
// import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
// import { Form } from '@unform/web';
// import Pagination from 'react-paginate';
import {
  // Button,
  Card,
  CardBody,
  CardHeader,
  // Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  // Modal,
  // ModalBody,
  // ModalFooter,
  // ModalHeader,
  // Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import api from '../../services/api';
import useValidator from '../../hooks/useValidator';
// import { Input, Select } from '../../components/unform';
import UsuarioService from 'services__new/UsuarioService';

function formatarCPFCNPJ(cpfcnpj) {
  cpfcnpj = String(cpfcnpj || '').replace(/\D/g, '');

  if (cpfcnpj.length === 11) {
    return cpfcnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  if (cpfcnpj.length === 14) {
    return cpfcnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }
  return cpfcnpj;
}
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function UsuariosInternos() {
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();
  const [result, setResult] = useState([]);

  async function getDados() {
    showLoader();
    const { data } = await UsuarioService.getAllInternalUsers();

    setResult(data);
    closeLoader();
  }

  useEffect(() => {
    getDados();
  }, []);

  const handleEdit = (id) => {
    history.push(`/usuarios/novo?id=${id}`);
  };

  async function handleDeleteUser(id) {
    swalWithBootstrapButtons
      .fire({
        title: 'Tem certeza que deseja excluir este usuário?',
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((r) => {
        if (r.value) {
          showLoader();
          api
            .delete(`/usuarios/internos/${id}`)
            .then(() => {
              setResult(result?.filter((f) => f.id !== id));
              toast('Usuário excluido com sucesso!', { type: 'success' });
              closeLoader();
            })
            .catch((error) => {
              const msg = error?.response?.data?.error;
              toast(msg, { type: 'error' });
              closeLoader();
            });
        }
      });
  }

  return (
    <>
      <Card>
        <CardHeader className="bg--dark-blue p-3 mb-3 d-flex justify-content-between">
          <h4 className="color-white mb-0">Usuários internos</h4>
          <Link className="btn btn-primary" to="/usuarios/novo">
            Novo
          </Link>
        </CardHeader>
        <CardBody>
          <Table className="rwd-table min-height" responsive>
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Documento</th>
                <th>Status</th>
                <th className="text-end">Ações</th>
              </tr>
            </thead>
            <tbody>
              {result?.map((m) => (
                <tr key={m?.id}>
                  <td data-label="Nome">{m?.nome}</td>
                  <td data-label="E-mail">{m?.email}</td>
                  <td data-label="Documento">
                    {formatarCPFCNPJ(m?.documento)}
                  </td>
                  <td data-label="Status">{m?.status}</td>
                  <td
                    data-label="Ações"
                    className="d-flex justify-content-lg-end justify-content-sm-between"
                  >
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="light-blue"
                        className="text-decoration-none padding-0-5"
                      >
                        <FiMoreVertical />
                      </DropdownToggle>
                      <DropdownMenu end container="body">
                        <DropdownItem onClick={() => handleEdit(m.id)}>
                          <IoCreateOutline className="mr-1" />
                          Editar
                        </DropdownItem>
                        <DropdownItem onClick={() => handleDeleteUser(m.id)}>
                          <IoTrashBinOutline className="mr-1" />
                          Excluir
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))}
              {result.items?.length === 0 && (
                <tr>
                  <td colSpan={7} className="text-center">
                    Sem dados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
}

export default UsuariosInternos;
