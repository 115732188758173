import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Table } from 'reactstrap';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import LineChart from '../../components/charts/line';
import BarChart from '../../components/charts/bar';
import useValidator from '../../hooks/useValidator';
import { Input } from '../../components/unform';
import { groupBy } from '../../utils';
import { currencyConfig } from '../../utils/currency';
import { useAuth } from '../../contexts/auth';
import DashboardService from 'services__new/DashboardService';

const nomeMes = {
  0: 'JAN',
  1: 'FEV',
  2: 'MAR',
  3: 'ABR',
  4: 'MAI',
  5: 'JUN',
  6: 'JUL',
  7: 'AGO',
  8: 'SET',
  9: 'OUT',
  10: 'NOV',
  11: 'DEZ',
};

const colorsStatus = {
  Sugerido: '#81DEF0',
  Finalizado: '#C0BB87',
  Enviado: '#0083A7',
  Cancelado: '#DB324D',
  Aprovado: '#002B49',
  Pendente: '#7D8491',
  Vencido: '#BB4430',
  Erro: '#FF003F',
};
const colorsLabelStatus = {
  Sugerido: '#00506E',
  Finalizado: '#494433',
  Enviado: '#fff',
  Cancelado: '#fff',
  Aprovado: '#fff',
  Pendente: '#fff',
  Vencido: '#fff',
  Erro: '#FFF',
};

function Dashboard() {
  const { user } = useAuth();
  const [dados, setDados] = useState({});
  const { showLoader, closeLoader, toast } = useValidator();
  const history = useHistory();
  const [filtros, setFiltros] = useState(() => {
    const currentYear = new Date().getFullYear();
    const dataInicio = new Date(currentYear, 0, 1).toISOString().split('T')[0];
    const dataFim = new Date(currentYear, 11, 31).toISOString().split('T')[0];

    return {
      dataInicio,
      dataFim,
    };
  });
  useEffect(() => {
    const getData = async () => {
      showLoader();
      try {
        const [
          { data: d1 },
          { data: d2 },
          { data: d3 },
          { data: d4 },
          { data: d5 },
          { data: d6 },
        ] = await Promise.all([
          DashboardService.getSent(filtros.dataInicio, filtros.dataFim),
          DashboardService.getError(filtros.dataInicio, filtros.dataFim),
          DashboardService.getOutdated(filtros.dataInicio, filtros.dataFim),
          DashboardService.getDelivered(filtros.dataInicio, filtros.dataFim),
          DashboardService.getPending(filtros.dataInicio, filtros.dataFim),
          DashboardService.getFinished(filtros.dataInicio, filtros.dataFim),
        ]);

        const groupPerfil = groupBy(d2, 'perfil');

        setDados({
          totalCadastros: d1,
          cadastrosPorMes: [
            {
              id: 'Estab. comercial',
              data: groupPerfil?.E?.length
                ? groupPerfil?.E?.map((m) => ({
                    x: nomeMes[m?.mes_numero - 2],
                    y: m?.quantidade_cadastros,
                  }))
                : [],
            },
            {
              id: 'Financiador',
              data: groupPerfil?.F?.length
                ? groupPerfil?.F?.map((m) => ({
                    x: nomeMes[m?.mes_numero - 2],
                    y: m?.quantidade_cadastros,
                  }))
                : [],
            },
          ],
          somaOperacoes: d3,
          totalOperacoes: d4,
          qtdOperacoesFomento: d5,
          qtdOperacoesEc: d6,
        });
      } catch (err) {
        toast(err.mensagem);
      }
      closeLoader();
    };
    getData();
  }, [filtros]);

  const resultOperacoesEc = (dados?.qtdOperacoesEc || [])?.reduce(
    (acc, item) => {
      acc.qtd += item.quantidade;
      acc.total += item.valor;

      return acc;
    },
    {
      qtd: 0,
      total: 0,
    }
  );

  const resultOperacoesFomento = (dados?.qtdOperacoesFomento || [])?.reduce(
    (acc, item) => {
      acc.qtd += item.quantidade;
      acc.total += item.valor;

      return acc;
    },
    {
      qtd: 0,
      total: 0,
    }
  );

  const handleClickEc = (id) => {
    history.push(
      `/operacoes?pessoa_id_ec=${id}&sugestao_inicio=${filtros.dataInicio}&sugestao_fim=${filtros.dataFim}`
    );
  };

  const handleClickFomento = (id) => {
    history.push(
      `/operacoes?pessoa_id_fomento=${id}&sugestao_inicio=${filtros.dataInicio}&sugestao_fim=${filtros.dataFim}`
    );
  };

  return (
    <>
      <Card body>
        <Form className="row" onSubmit={(data) => setFiltros(data)}>
          <Col lg={3}>
            <Input
              label="Data início"
              className="form-control"
              name="dataInicio"
              defaultValue={filtros.dataInicio}
              type="date"
            />
          </Col>
          <Col lg={3}>
            <Input
              label="Data fim"
              className="form-control"
              name="dataFim"
              defaultValue={filtros.dataFim}
              type="date"
            />
          </Col>
          <Col lg={3}>
            <div className="mt-2">&nbsp;</div>
            <Button color="primary" block>
              Consultar
            </Button>
          </Col>
        </Form>
      </Card>
      <Row>
        <Col xl={7}>
          <div className="chart-card-lg">
            <h5 className="text-center pt-3">Total por status</h5>
            <div className="chart-card-body">
              <BarChart
                data={dados?.somaOperacoes || []}
                indexBy="status"
                keys={['valor_total']}
                enableGridY={false}
                colors={(e) => colorsStatus[e.indexValue]}
                labelTextColor={(e) => colorsLabelStatus[e.label]}
                axisLeft={null}
                tooltipLabel={(e) => e.indexValue}
                valueFormat={(e) => Number(e).toLocaleString(...currencyConfig)}
                axisBottom={{
                  renderTick: ({
                    opacity,
                    textAnchor,
                    textBaseline,
                    textX,
                    textY,
                    value,
                    x,
                    y,
                  }) => (
                    <g
                      key={value}
                      transform={`translate(${x},${y})`}
                      style={{ opacity }}
                    >
                      <text
                        alignmentBaseline={textBaseline}
                        textAnchor={textAnchor}
                        style={{
                          fontFamily: 'sans-serif',
                          fontSize: '11px',
                          fill: 'rgb(0, 0, 0)',
                          fontWeight: 600,
                        }}
                        transform={`translate(${textX},${textY})`}
                      >
                        <tspan y={7} dx="-2px">
                          {value}
                        </tspan>
                      </text>
                    </g>
                  ),
                }}
                margin={{ top: 20, right: 20, bottom: 40, left: 20 }}
              />
            </div>
          </div>
        </Col>
        <Col xl={5}>
          {user?.perfil === 'F' && (
            <Card>
              <div className="chart-card-lg">
                <h5 className="text-center pt-3">Operações por EC</h5>
                <div className="chart-card-body" style={{ overflow: 'auto' }}>
                  <Table className="rwd-table" responsive>
                    <thead>
                      <tr>
                        <th>Estabelecimento</th>
                        <th>Status</th>
                        <th className="text-center">Qtd</th>
                        <th className="text-center">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(dados?.qtdOperacoesEc || [])?.map((m) => (
                        <tr key={m?.nome + m?.status}>
                          <td data-label="Estabelecimento">
                            <button
                              className="btn-none"
                              onClick={() => handleClickEc(m.pessoa_id_ec)}
                              type="button"
                            >
                              {m?.nome}
                            </button>
                          </td>
                          <td data-label="Status">{m?.status}</td>
                          <td data-label="Qtd" className="text-center">
                            {m?.quantidade}
                          </td>
                          <td data-label="Valor" className="text-center">
                            {Number(m?.valor || 0).toLocaleString(
                              ...currencyConfig
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td />
                        <td className="bold">Total</td>
                        <td className="bold text-center">
                          {resultOperacoesEc?.qtd}
                        </td>
                        <td className="text-center bold">
                          {Number(resultOperacoesEc?.total || 0).toLocaleString(
                            ...currencyConfig
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>
          )}
          {(user?.perfil === 'E' || user?.perfil === 'A') && (
            <Card>
              <div className="chart-card-lg">
                <h5 className="text-center pt-3">Operações por fomento</h5>
                <div className="chart-card-body" style={{ overflow: 'auto' }}>
                  <Table className="rwd-table" responsive>
                    <thead>
                      <tr>
                        <th>Financiador</th>
                        <th>Status</th>
                        <th className="text-center">Qtd</th>
                        <th className="text-center">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(dados?.qtdOperacoesFomento || [])?.map((m) => (
                        <tr key={m?.nome + m?.status}>
                          <td data-label="Financiador">
                            <button
                              className="btn-none"
                              onClick={() =>
                                handleClickFomento(m.pessoa_id_fomento)
                              }
                              type="button"
                            >
                              {m?.nome}
                            </button>
                          </td>
                          <td data-label="Status">{m?.status}</td>
                          <td data-label="Qtd" className="text-center">
                            {m?.quantidade}
                          </td>
                          <td data-label="Valor" className="text-center">
                            {Number(m?.valor || 0).toLocaleString(
                              ...currencyConfig
                            )}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td />
                        <td className="bold">Total</td>
                        <td className="bold text-center">
                          {resultOperacoesFomento?.qtd}
                        </td>
                        <td className="text-center bold">
                          {Number(
                            resultOperacoesFomento?.total || 0
                          ).toLocaleString(...currencyConfig)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Card>
          )}
        </Col>
        {user?.perfil === 'A' && (
          <>
            <Col xl={3}>
              <Card>
                <div className="chart-card-xs">
                  <h5 className="text-center pt-3">Total de cadastros</h5>
                  <div className="chart-card-body" style={{ overflow: 'auto' }}>
                    <div className="pt-3" />
                    <div className="pt-1" />
                    <h6 className="text-center pt-5">
                      Financiador:{' '}
                      {
                        dados?.totalCadastros?.find((f) => f?.perfil === 'F')
                          ?.count
                      }
                    </h6>
                    <h6 className="text-center pt-3">
                      Estab comercial:{' '}
                      {
                        dados?.totalCadastros?.find((f) => f?.perfil === 'E')
                          ?.count
                      }
                    </h6>
                  </div>
                </div>
              </Card>
              <Card>
                <div className="chart-card-sm">
                  <h5 className="text-center pt-3">Total de operações</h5>
                  <div className="chart-card-body">
                    <Row className="align-items-center h-100 pb-3 pt-3">
                      {(dados?.totalOperacoes || [])?.map((m) => (
                        <Col lg={12}>
                          <h6 className="text-center mb-1">
                            {m?.status}: {m?.['']}
                          </h6>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xl={9}>
              <div className="chart-card-lg">
                <h5 className="text-center pt-3">Evolução e declínio mensal</h5>
                <div className="chart-card-body">
                  <LineChart data={dados?.cadastrosPorMes || []} />
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
}

export default Dashboard;
