import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import CurrencyInput from 'react-currency-input-field';

function NumberFormat({
  name,
  label,
  className = '',
  group = false,
  defaultValue,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    error,
    defaultValue: valueDef,
  } = useField(name);

  const [number, setNumber] = useState(Number(defaultValue ?? valueDef ?? 0));

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: () => Number(String(number).replace(/,/g, '.')).toFixed(2),
      setValue: (ref, value) => {
        setNumber(Number(value ?? 0).toFixed(2));
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField, number]);

  if (group) {
    return (
      <CurrencyInput
        ref={inputRef}
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        decimalsLimit={2}
        className={[className, error ? 'error' : ''].join(' ')}
        id={fieldName}
        groupSeparator="."
        decimalSeparator=","
        prefix="R$"
        value={number}
        onValueChange={setNumber}
        {...rest}
      />
    );
  }

  return (
    <div className="form-group">
      <label htmlFor={fieldName} className="form-label">
        {label}
      </label>

      <CurrencyInput
        ref={inputRef}
        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
        decimalsLimit={2}
        className={[className, error ? 'error' : ''].join(' ')}
        id={fieldName}
        groupSeparator="."
        decimalSeparator=","
        prefix="R$"
        value={number}
        defaultValue={valueDef !== undefined ? valueDef : 0}
        onValueChange={setNumber}
        {...rest}
      />
      {error && <small className="error">{error}</small>}
    </div>
  );
}

export default NumberFormat;
