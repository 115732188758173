import api from 'services/api';

class AcordoService {
  static create(data = {}) {
    try {
      return api.post('/optin/', data);
    } catch (e) {
      console.error('Erro ao criar acordo:', e);
    }
  }

  static getAll(data = {}) {
    try {
      const { page = 1, query = '' } = data;
      return api.get(`/acordos?page=${page}&${query}`);
    } catch (e) {
      console.error('Erro ao buscar acordos:', e);
    }
  }

  static getHistoryById(id = '') {
    try {
      return api.get(`/acordo/historico/${id}`);
    } catch (e) {
      console.error('Erro ao buscar histórico do acordo:', e);
    }
  }

  static optOut(id = '') {
    try {
      return api.put(`/optout/${id}`);
    } catch (e) {
      console.error('Erro ao opt-out do acordo:', e);
    }
  }

  static getDocumentById(data = {}) {
    try {
      const { id = '', tipo = '' } = data;
      return api.get(`/url-documento/${id}/${tipo}`);
    } catch (e) {
      console.error('Erro ao buscar documento do acordo:', e);
    }
  }
}

export default AcordoService;
