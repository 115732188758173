import React from 'react';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from './contexts/auth';
import { ThemeProvider } from './contexts/theme';
import Routes from './routes';

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <Routes />
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
