export const currencyOptions = {
  currency: 'BRL',
  style: 'currency',
};
export const fixed2 = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const currencyLocale = 'pt-BR';

export const currencyConfig = [currencyLocale, currencyOptions];
