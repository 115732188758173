import brasilApi from 'services/brasilApi';

class BrasilService {
  static getAddressByCep(cep = '') {
    try {
      return brasilApi.get(`/cep/v2/${cep}`);
    } catch (error) {
      console.error('Erro ao buscar endereço pelo CEP:', error);
    }
  }
}

export default BrasilService;
