import React, {
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { useField } from '@unform/core';

const Input = forwardRef(
  (
    {
      name,
      label,
      type,
      className = '',
      group = false,
      labelClass,
      defaultValue: df,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef(null);
    const {
      fieldName,
      defaultValue: df2,
      registerField,
      error,
    } = useField(name);

    const defaultValue = df || df2;

    useImperativeHandle(ref, () => ({
      focus: () => inputRef.current.focus(),
      blur: () => inputRef.current.blur(),
      setMinDate: (minDate) => {
        if (type === 'date') {
          if (!ref.current || !inputRef.current) return;
          inputRef.current.min = minDate;
        }
      },
    }));

    useEffect(() => {
      registerField({
        name: fieldName,
        ref: inputRef.current,
        getValue: (localRef) => {
          if (type === 'file') {
            return localRef.files[0];
          }
          if (type === 'number') {
            return Number(localRef.value);
          }
          return localRef.value;
        },
        setValue: (localRef, value) => {
          if (type === 'date') {
            localRef.value = value ? value.split('T')[0] : '';
          } else if (typeof value === 'object' && type === 'file') {
            localRef.value = value;
          } else if (type !== 'file') {
            localRef.value = value;
          }
        },
        clearValue: (localRef) => {
          localRef.value = '';
        },
      });
    }, [fieldName, registerField, type]);

    if (group) {
      return (
        <input
          ref={inputRef}
          defaultValue={defaultValue}
          type={type || 'text'}
          className={[className, error ? 'error' : ''].join(' ')}
          {...rest}
        />
      );
    }

    return (
      <div className="form-group">
        <label
          htmlFor={fieldName}
          className={'form-label '.concat(labelClass || '')}
        >
          {label}
        </label>

        <input
          ref={inputRef}
          defaultValue={defaultValue}
          type={type || 'text'}
          className={[className, error ? 'error' : ''].join(' ')}
          {...rest}
        />

        {error && <small className="error">{error}</small>}
      </div>
    );
  }
);

export default Input;
