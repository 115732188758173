/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar';
import {
  // FiBell,
  FiPower,
  FiUser,
} from 'react-icons/fi';
import {
  IoBusiness,
  IoCard,
  IoFileTrayFull,
  IoHome,
  IoStorefront,
  IoPersonAdd,
  IoCash,
  IoReaderSharp,
  IoRefreshSharp,
  IoRepeatSharp,
} from 'react-icons/io5';
import {
  FaAlignLeft,
  FaAlignJustify,
  FaChevronLeft,
  FaAlignRight,
} from 'react-icons/fa';
import { useLayoutEffect, useState } from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logo from '../assets/svg/logo.svg';
// import logo from '../assets/svg/logo-icon.svg';
// import logoText from '../assets/svg/logo-text.svg';
import { useAuth } from '../contexts/auth';
import { useTheme } from '../contexts/theme';

const size = 20;

const routeTitles = [
  {
    value: '/estabelecimentos-comerciais/novo',
    label: 'Novo estab. comercial',
  },
  {
    value: '/financiadores/novo',
    label: 'Novo financiador',
  },
];

const routes = [
  {
    label: 'Dashboard',
    value: '/',
    icon: <IoHome size={size} />,
    tela: 1,
  },
  {
    label: 'Estab. comercial',
    value: '/estabelecimentos-comerciais',
    icon: <IoStorefront size={size} />,
    tela: 2,
  },
  {
    label: 'Financiador',
    value: '/financiadores',
    icon: <IoBusiness size={size} />,
    tela: 2,
  },
  {
    label: 'Acordos',
    value: '/acordos',
    icon: <IoFileTrayFull size={size} />,
    tela: 3,
  },
  {
    label: 'Agenda',
    value: '/agenda',
    icon: <IoCard size={size} />,
    tela: 4,
  },
  {
    label: 'Operações',
    value: '/operacoes',
    icon: <IoCash size={size} />,
    tela: 5,
  },
  {
    label: 'Usuários internos',
    value: '/usuarios',
    icon: <IoPersonAdd size={size} />,
    tela: 6,
  },
  {
    label: 'Logs',
    value: '/logs',
    icon: <IoReaderSharp size={size} />,
    tela: 7,
  },
  {
    label: 'Webhooks',
    value: '/webhooks',
    icon: <IoRefreshSharp size={size} />,
    tela: 8,
  },
  {
    label: 'Conciliação',
    value: '/conciliacao',
    icon: <IoRepeatSharp size={size} />,
    tela: 9,
  },
];

export const templateName = 'Livecard';

const Layout = ({ children }) => {
  const { user, signOut } = useAuth();
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const [rotasFilter, setRotasFilter] = useState([]);

  const history = useHistory();
  const location = useLocation();
  const { setTitle } = useTheme();
  // const { isDarkMode, toggleTheme, title } = useTheme();

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleToggled = () => {
    if (toggled) {
      setToggled(false);
    }
  };
  useLayoutEffect(() => {
    if (!user) {
      document.title = templateName;
      return;
    }
    const currentRoute =
      routeTitles.find((f) => f.value === location.pathname) ||
      routes.find((f) => f.value === location.pathname);
    if (currentRoute) {
      document.title = ` ${
        currentRoute.title || currentRoute.label
      } | ${templateName}`;
    } else {
      document.title = templateName;
    }

    const validaTelas = routes.map((menu) => {
      if (user?.tipo === 'S') {
        if (menu.tela) {
          if (
            user?.acessos?.some(
              (acesso) => Number(acesso.acessos) === Number(menu.tela)
            )
          ) {
            return menu;
          }

          return null;
        }
      } else if (user?.perfil !== 'A') {
        return menu.tela >= 7 ? null : menu;
      }

      return menu;
    });

    setRotasFilter(validaTelas.filter((v) => v !== null));

    setTitle(currentRoute?.label || '');
  }, [location, setTitle, user]);

  return (
    <div className="d-flex bg-gray min-100h">
      <ProSidebar
        collapsed={collapsed}
        breakPoint="md"
        toggled={toggled}
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader
          className="d-flex justify-content-end"
          style={{
            marginTop: 15,
            marginRight: 20,
            marginBottom: 15,
          }}
        >
          <div className="md-none">
            <Button color="link" onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? (
                <FaAlignLeft size={20} color="#646885" />
              ) : (
                <FaAlignRight size={20} color="#646885" />
              )}
            </Button>
          </div>
          {/* <div className="sidebar-header-main">
            <Link to="/">
              <img
                src={logo}
                alt="logo simbolo"
                className="object-fit"
                style={{
                  height: 50,
                  width: 50,
                }}
              />
              {!collapsed && (
                <img
                  alt="logo text"
                  className="object-fit"
                  src={logoText}
                  style={{
                    height: 40,
                    width: 170,
                    marginLeft: 10,
                  }}
                />
              )}
            </Link>
          </div> */}
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            {rotasFilter
              ?.filter((f) => {
                if (user?.perfil === 'F' && f.value === '/financiadores') {
                  return false;
                }
                if (
                  user?.perfil === 'E' &&
                  f.value === '/estabelecimentos-comerciais'
                ) {
                  return false;
                }
                if (user?.perfil === 'A' && f.value === '/agenda') {
                  return false;
                }
                return true;
              })
              .map((m) =>
                m.value ? (
                  <MenuItem
                    key={m.value}
                    icon={m.icon}
                    active={location.pathname === m.value}
                    onClick={handleToggled}
                  >
                    <Link to={m.value}>{m.label}</Link>
                  </MenuItem>
                ) : (
                  <SubMenu
                    key={m.label}
                    title={m.label}
                    icon={m.icon}
                    className={
                      m.items.some((sub) => location.pathname === sub.value)
                        ? 'has-active'
                        : ''
                    }
                  >
                    {m.items.map((sub) => (
                      <MenuItem
                        key={sub.value}
                        active={location.pathname === sub.value}
                        onClick={handleToggled}
                      >
                        <Link to={sub.value}>{sub.label}</Link>
                      </MenuItem>
                    ))}
                  </SubMenu>
                )
              )}
            <MenuItem
              icon={<FiUser size={size} />}
              active={location.pathname === '/perfil'}
              onClick={handleToggled}
              className="d-md-none"
            >
              <Link to="/perfil">Perfil</Link>
            </MenuItem>
          </Menu>
        </SidebarContent>
        <SidebarFooter hidden>
          <div className="justify-center text-center my-3 xcoding pb-1">
            Made with &nbsp;
            <span
              style={{
                color: 'red',
              }}
            >
              ❤
            </span>
            &nbsp; by &nbsp;
            <a
              href="https://xcoding.com.br"
              rel="noreferrer"
              target="_blank"
              style={{
                color: 'rgb(var(--theme-color-rgb))',
                fontWeight: 600,
              }}
            >
              Xcoding
            </a>
          </div>

          <div className="justify-center text-center my-3 xcoding-text pb-1">
            <a
              href="https://xcoding.com.br"
              rel="noreferrer"
              target="_blank"
              style={{
                color: 'rgb(var(--theme-color-rgb))',
                fontWeight: 600,
              }}
            >
              Xcoding
            </a>
          </div>
        </SidebarFooter>
      </ProSidebar>
      <div className="dashboard w-100">
        <div className="navbar-container d-flex justify-content-between align-items-center">
          <div className="md-none d-lg-flex align-items-center">
            <img src={logo} height={25} alt="logo" />{' '}
            <span
              className="color-dark-blue"
              style={{
                fontSize: 17,
                fontWeight: 600,
                marginLeft: 10,
              }}
            >
              | {templateName}
            </span>
          </div>
          <Button
            color="link"
            className="d-md-none"
            onClick={() => history.goBack()}
          >
            <FaChevronLeft />
          </Button>
          <div className="mb-0 d-md-none align-items-center">
            <img src={logo} className="mb-1" height={16} alt="logo" />{' '}
            <span
              className="color-dark-blue"
              style={{
                fontSize: 15,
                fontWeight: 600,
                marginLeft: 10,
              }}
            >
              | {templateName}
            </span>
          </div>
          <Button
            color="link"
            className="d-md-none"
            onClick={() => handleToggleSidebar(true)}
          >
            {collapsed ? (
              <FaAlignJustify size={20} color="var(--color-primary)" />
            ) : (
              <FaAlignLeft size={20} color="var(--color-primary)" />
            )}
          </Button>
          <div className="d-lg-flex md-none">
            {/* <Button color="link" onClick={toggleTheme}>
              {isDarkMode ? (
                <FiSun color="var(--body-color)" size={24} />
              ) : (
                <FiMoon color="var(--body-color)" size={24} />
              )}
            </Button> */}
            {/* <Button color="link">
              <FiBell color="var(--dark-blue)" size={22} />
            </Button> */}
            <h5 className="mt-2 mr-3">{user?.nome}</h5>
            <UncontrolledDropdown direction="down">
              <DropdownToggle
                color="link"
                className="nav-user d-flex align-items-center"
              >
                <FiUser color="var(--dark-blue)" size={22} />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    history.push('/perfil');
                  }}
                >
                  <FiUser className="mr-1" size={18} />
                  Perfil
                </DropdownItem>
                <div tabIndex="-1" className="dropdown-divider" />
                <DropdownItem
                  onClick={() => {
                    signOut();
                    setTitle('Template');
                    history.push('/');
                  }}
                >
                  <FiPower className="mr-1" size={18} />
                  Sair
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Layout;
