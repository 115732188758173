import api from 'services/api';

class ConciliacaoService {
  static getAll(data = {}) {
    try {
      const { page = 1, query = '' } = data;
      return api.get(`/listarconcilia?page=${page}&${query}`);
    } catch (e) {
      console.error('Erro ao buscar conciliacoes:', e);
    }
  }
}

export default ConciliacaoService;
