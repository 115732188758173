import api from 'services/api';

class OperacaoService {
  static get(data = {}) {
    try {
      const { page = 1, query = '' } = data;
      return api.get(`/operacoes?page=${page}&${query}`);
    } catch (e) {
      console.error('Erro ao buscar operacoes:', e);
    }
  }

  static suggest(data = {}) {
    try {
      const { urs = [], ec_fo_id = '' } = data;
      return api.post('/operacoes/sugerir', { urs, ec_fo_id });
    } catch (e) {
      console.error('Erro ao sugerir operacao:', e);
    }
  }

  static approve(data = {}) {
    try {
      const { event = {}, operacao_id = '' } = data;
      return api.post('/operacoes/aprovar', { ...event, operacao_id });
    } catch (e) {
      console.error('Erro ao aprovar operacao:', e);
    }
  }

  static getAgenda(data = {}) {
    try {
      return api.post('/agenda', data);
    } catch (e) {
      console.error('Erro ao buscar agenda:', e);
    }
  }
}

export default OperacaoService;
