import api from 'services/api';

class IntegracaoService {
  static getAll(data = {}) {
    try {
      const { page = 1, query = '' } = data;
      return api.get(`/listarinegracoes?page=${page}&${query}`);
    } catch (error) {
      console.error('Erro ao buscar integrações:', error);
    }
  }

  static resend(data = {}) {
    try {
      const { id = '', error = {} } = data;
      return api.post(`/integracao/reenviar/${id}`, error);
    } catch (error) {
      console.error('Erro ao reenviar operação:', error);
    }
  }
}

export default IntegracaoService;
