import api from 'services/api';

class UsuarioService {
  static forgotPassword(data = {}) {
    try {
      return api.post('/senha/esqueci', data);
    } catch (error) {
      console.error('Erro ao recuperar senha:', error);
    }
  }

  static createPassword(data = {}) {
    try {
      return api.post('/senha', data);
    } catch (error) {
      console.error('Erro ao criar senha:', error);
    }
  }

  static updatePassword(data = {}) {
    try {
      return api.put('/alterar-senha', data);
    } catch (error) {
      console.error('Erro ao alterar senha:', error);
    }
  }

  static login(data = {}) {
    try {
      return api.post('/sessions', data);
    } catch (error) {
      console.error('Erro ao logar:', error);
    }
  }

  static getProfile() {
    try {
      return api.get('/perfil');
    } catch (error) {
      console.error('Erro ao buscar perfil:', error);
    }
  }

  static updateProfile(data = {}) {
    try {
      return api.put('/perfil', data);
    } catch (error) {
      console.error('Erro ao editar perfil:', error);
    }
  }

  static updateInternalUser(data = {}) {
    try {
      const { id = '', body = {} } = data;
      return api.put(`/usuarios/internos/${id}`, body);
    } catch (error) {
      console.error('Erro ao editar usuário interno:', error);
    }
  }

  static createInternalUser(data = {}) {
    try {
      return api.post('/usuarios/internos', data);
    } catch (error) {
      console.error('Erro ao cadastrar novo usuário interno:', error);
    }
  }

  static getInternalUser(id = '') {
    try {
      return api.get(`/usuario/interno/${id}`);
    } catch (error) {
      console.error('Erro ao buscar usuário interno:', error);
    }
  }

  static getAllInternalUsers() {
    try {
      return api.get('/usuarios/internos');
    } catch (error) {
      console.error('Erro ao buscar todos os usuários internos:', error);
    }
  }

  static preRegisterUser(data = {}) {
    try {
      return api.post('/precadastro', data);
    } catch (error) {
      console.error('Erro ao pre-registrar novo usuário:', error);
    }
  }
}

export default UsuarioService;
