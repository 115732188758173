/* eslint-disable no-nested-ternary */
import axios from 'axios';

// const api = axios.create({
//   baseURL: window.location.host.includes('localhost')
//     ? 'http://localhost:3333'
//     : 'https://api.livehub.com.vc',
// });
const api = axios.create({
  baseURL: 'https://api.livehub.com.vc',
});
// const api = axios.create({
//   baseURL: 'http://18.229.143.15',
// });

export default api;
