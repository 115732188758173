const imgs = {
  ACC: 'american-express.svg',
  AUC: 'aura.svg',
  BCC: 'banescard.svg',
  BCD: 'banescard.svg',
  CBD: 'cabal.svg',
  CBC: 'cabal.svg',
  DBC: 'discover.svg',
  DCC: 'diners-club.svg',
  ECC: 'elo.svg',
  ECD: 'elo.svg',
  HCC: 'hipercard.svg',
  HCD: 'hiper.svg',
  JCC: 'jcb.svg',
  MCC: 'mastercard.svg',
  MCD: 'maestro.svg',
  VCC: 'visa.svg',
  VCD: 'visa-electron.svg',
  AGC: 'agiplan.png',
  SPC: 'sem-parar.svg',
  AVC: 'avista.png',
  BNC: 'banese.png',
  BRS: 'banri-compras.png',
  BRC: 'brasil-card.png',
  CCD: 'calcard.png',
  CSC: 'credishop.png',
  CZC: 'credz.png',
  DAC: 'dacasa.png',
  FRC: 'fortbrasil.jpg',
  GCC: 'goodcard.png',
  MAC: 'mais.png',
  MXC: 'maxifrota.png',
  OCD: 'ourocard.jpg',
  SCC: 'sorocred.png',
  SCD: 'sorocred.png',
  SIC: 'sicredi.jpg',
  TKC: 'ticketlog.png',
  VDC: 'verdecard.jpg',
  SFC: 'senff.png',
};

const nomes = {
  ACC: 'Amex Cartão de Crédito',
  AGC: 'Agiplan Cartão de Crédito',
  AUC: 'Aura Cartão de Crédito',
  AVC: 'Avista Cartão de Crédito',
  BCC: 'Banescard Cartão de Crédito',
  BCD: 'Banescard Cartão de Débito',
  BNC: 'Banese Crédito',
  BRC: 'Brasil Card Cartão de Crédito',
  BRS: 'Banrisul (Compra)',
  CBC: 'Cabal Crédito',
  CBD: 'Cabal Débito',
  CCD: 'Calcard Cartão de Crédito',
  CSC: 'Credi-Shop Cartão de Crédito',
  CUP: 'CUP Cartão de Crédito',
  CZC: 'Credz Cartão de Crédito',
  DAC: 'Dacasa Cartão de Crédito',
  DBC: 'Discover Cartão de Crédito',
  DCC: 'Dinners Cartão de Crédito',
  ECC: 'Elo Cartão de Crédito',
  ECD: 'Elo Cartão de Débito',
  FRC: 'Fortbrasil Cartão de Crédito',
  GCC: 'Goodcard Crédito',
  HCC: 'Hipercard Cartão de Crédito',
  HCD: 'Hipercard Cartão de Débito',
  JCC: 'JCB Cartão de Crédito',
  MAC: 'Mais Cartão de Crédito',
  MCC: 'Mastercard Cartão de Crédito',
  MCD: 'Mastercard Cartão de Débito',
  MXC: 'Maxifrota Cartão de Crédito',
  OCD: 'Ourocard Cartão de Débito',
  RCC: 'Redesplan Cartão de Crédito',
  SCC: 'Sorocred Cartão de Crédito',
  SCD: 'Sorocred Cartão de Débito',
  SFC: 'Senff Cartão de Crédito',
  SIC: 'Sicred (Compra)',
  SPC: 'Sem Parar Cartão de Crédito',
  TKC: 'Ticketlog Pós Cartão de Crédito',
  VCC: 'Visa Cartão de Crédito',
  VCD: 'Visa Cartão de Débito',
  VDC: 'Verdecard Cartão de Crédito',
};

export { imgs, nomes };
